import React from "react";

const CalendarSidebar = ({state,handleWeekendsToggle,renderSidebarEvent}) => {

/* console.log(state);
console.log("=========================");
console.log(handleWeekendsToggle); */


  return (
    <div className='demo-app-sidebar'>
      <div className='demo-app-sidebar-section'>
        <h2>Instructions</h2>
        <ul>
          <li>Select dates and you will be prompted to create a new event</li>
          <li>Drag, drop, and resize events</li>
          <li>Click an event to delete it</li>
        </ul>
      </div>
      <div className='demo-app-sidebar-section'>
        <label>
          <input
            type='checkbox'
            //checked={state.weekendsVisible}
            //onChange={handleWeekendsToggle}
          />
          toggle weekends
        </label>
      </div>
      <div className='demo-app-sidebar-section'>
        <h2>All Events ({state.currentEvents.length})</h2>
        <ul>
          {state.currentEvents.map(renderSidebarEvent)}
        </ul>
      </div>
    </div>
  )
}

export default CalendarSidebar;
