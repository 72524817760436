import React, { useEffect, useState } from "react";
import axios from "axios";
//import { ToastContainer } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";
import { withRouter } from "react-router-dom";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import Header from "../../components/Header/Header";
import ProductList from "../../components/Product/ProductList/ProductList";
import AddProductModal from "../../components/Product/ProductModal/AddProductModal.js";
import UpdateProductmodal from "../../components/Product/ProductModal/UpdateProductmodal";

const Product = () => {
  const [product, setProduct] = useState([]);
  const [productData, setProductData] = useState([]);
  const [supplier, setSupplier] = useState([]);

  const openModal = (data) => {
    setProductData(data);
  };

  //Get product list
  const getProductList = () => {
    axios
      .get(API_BASE_URL + "/product-list", HEADERS)
      .then(function (response) {
        if (response.status === 200) {
          setProduct(response.data);
        }
      });
  };

  const getSupplierLsit = () => {
    axios
      .get(`${API_BASE_URL}/suppliers-dropdown`, HEADERS)
      .then((response) => {
        setSupplier(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProductList();
    getSupplierLsit();
  }, []);

  return (
    <React.Fragment>
      <Header />
      {/* <ToastContainer /> */}
      {/*<!-- Wrapper --> */}
      <div id="settings" className="mainWrapper">
        <div id="settingsBar" className="statusBar settingsBar container-fluid">
          <div className="leftSide pageTitle">
            <h4 className="barTitle">Settings</h4>
          </div>
          <div className="rightSide AddNew">
            <button
              className="btn btn-primary purple ms-2"
              data-bs-toggle="modal"
              data-bs-target="#CreateProductModal"
            >
              New Product
            </button>
          </div>
        </div>
        <div
          id="setPanelWrap"
          className="setWrap panelWrap bodyWrap p-0 container-fluid"
        >
          <div className="row m-0 g-0 justify-content-end">
            <div id="left" className="serMenu leftSide sideBarMenu p-0 col">
              <LeftSidebar />
            </div>
            <div
              id="right"
              className="rightSide col panel serDetails setBodyDetails"
            >
              <div className="row g-0">
                <div className="topPart col-12">
                  <div className="row align-items-center">
                    <div className="panelHead col">
                      <h3 className="mb-0">
                        <i className="fas fa-circle success"></i> Active
                        Products
                      </h3>
                    </div>
                    <div className="col-3 right filterSearch staffInvite text-end">
                      <button className="btn btn-simple p-0 waves-effect waves-light">
                        Active <i className="fas fa-chevron-down"></i>
                      </button>

                      {/* <button
												type="button"
												className="btn btn-simple p-0 ms-3 me-3"
											>
												<i className="far fa-search"></i>
											</button> */}
                    </div>
                  </div>
                </div>
                {/* product list */}
                <ProductList product={product} openModal={openModal} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add product model */}
      <AddProductModal getProductList={getProductList} supplier={supplier} />

      {/* update product model */}
      <UpdateProductmodal
        productData={productData}
        getProductList={getProductList}
        supplier={supplier}
      />
    </React.Fragment>
  );
};

export default withRouter(Product);
