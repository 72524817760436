
let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: todayStr,
    resourceId: 'a',
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T12:00:00',
    resourceId: 'b',
  }
]

export function createEventId() {
  return String(eventGuid++)
}

/* const [state, setState] = useState({
    weekendsVisible: true,
    externalEvents: [
      { title: "Art 1", color: "#0097a7", id: 34432, custom: "fdsfdsfds" },
      { title: "Art 2", color: "#f44336", id: 323232 },
      { title: "Art 3", color: "#f57f17", id: 1111 },
      { title: "Art 4", color: "#90a4ae", id: 432432 }
    ],
    calendarEvents: [
      {
        id: 1,
        title: "All-day event",
        color: "#388e3c",
        start: "2021-5-12",
        end: "2021-5-12",
        custom: "questo è un campo custom"
      },
      {
        id: 2,
        title: "Timed event",
        color: "#0097a7",
        start: "2021-5-07",
        end: "2021-5-10",
        custom: "custom stuff"
      }
    ]
  }); */
