import React from "react";

const CategoryList = ({ categoriesList, openModal }) => {
  return (
    <div className="row g-0">
      <div className="col panel serblock p-0">
        <table className="panelTable shadow fixed-layout table table-hover">
          <thead>
            <tr>
              <th style={{ width: "80px" }}>#Sr. No.</th>
              <th>CONSULTATION</th>
              <th style={{ width: "200px" }}>Scheduling Order</th>
            </tr>
          </thead>
          <tbody>
            {categoriesList.map((category, index) => (
              <tr key={index} data-id={category.id}>
                <td>{index + 1}</td>
                <td>{category.category_name}</td>
                <td style={{ width: "200px" }}>
                  <button
                    type="button"
                    className="btn btn-warning btn-sm me-2"
                    data-bs-toggle="modal"
                    data-bs-target={"#UpdateCateModal"}
                    onClick={() => openModal(category)}
                  >
                    Edit
                  </button>

                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target={"#deleteCatModal"}
                    onClick={() => openModal(category)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CategoryList;
