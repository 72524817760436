import React from "react";
import { useHistory } from "react-router-dom";
//import { useForm, Controller } from "react-hook-form";
//import { useState } from "react";
import axios from "axios";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";
import { toast } from "react-toastify";
import { Capitalize } from "../../Helper/Utilites";

const ListofLocation = ({ locations, openModal }) => {
	
	const handleSubmit = (e) => {
		let locationId = { locationId: e.target.value };

		axios
			.post(`${API_BASE_URL}/update-default-location`, locationId, HEADERS)
			.then((response) => {
				if (response.status === 200) {
					toast.success("location update successfully");
					//reset();
					//ListServices();
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	/* const handleSubmit = (data) => {
		console.log(data);
		console.log(defloc);
	}; */

	const history = useHistory();
	const handelClick = (title) => {
		history.push(`/location/${title}`);
	};

	return (
		<div className="middlePart mt-3 col-12">
			<table className="panelTable shadow fixed-layout table table-hover">
				<thead>
					<tr>
						<th className="businessName">Business Name <i className="far fa-arrow-up"></i></th>
						<th className="nickName">Email <i className="far fa-arrow-up"></i></th>
						<th className="city">Contact <i className="far fa-arrow-up"></i></th>
						<th className="state">City <i className="far fa-arrow-up"></i></th>
						<th className="staff">State <i className="far fa-arrow-up"></i></th> 
						<th className="locManage">Default location</th> 
						<th className="locManage">Action</th>
					</tr>
				</thead>
				<tbody>
					{ locations.map((location, index) => (
						<tr key={index} data-id={location.id}>
							<td>{Capitalize(location.store_title)}</td>
							<td>{location.store_email}</td>
							<td>{location.store_contact_no}</td>
							<td>{Capitalize(location.store_city)}</td>
							<td>{Capitalize(location.store_state)}</td>
							<td>
								<div className="form-check col-12 form-switch">
									<input
										type="radio"
										name="default_location"
										//onChange={changeHandler}
										className="form-check-input"
										defaultChecked={location.default_location}
										value={location.id}
										onClick={handleSubmit}
										//onSubmit={handleSubmit}
										//{...register("default_location", { required: true })}
										//id="flexSwitchCheckDefault"
										// value={"defloc_" + location.id}
										//onSubmit={handleSubmit}
										//isChecked
										//{...register("default_location")}
									/>
								</div>
							</td>
							<td className="text-end">
								<span
									className="delete-location"
									data-bs-toggle="modal"
									data-bs-target={`#ModalDelete`}
									onClick={() => openModal(location)}
								>
									<i className="fal fa-trash-alt"></i>
								</span>

								<button className="btn btn-primary purple btn-sm" onClick={() => handelClick(location.title_slug)}>Manage</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{/*<div className="row g-0 mt-2">
				<div className="col panel locBlock p-0">
					
				</div>
			</div>*/}
		</div>
	);
};

export default ListofLocation;
