import React, { useState } from "react";
import { Capitalize, ToFixed } from "../../Helper/Utilites";

const tempColors = { "Bug Fix": 1, "UI Dev": 2, Testing: 3 };

export default function TrelloCard({ id, status, title, label,booking_datetime,showBookingDetails }) {
	const [onHold, setOnHold] = useState(false);

	const dragStartHandler = (e) => {
		e.dataTransfer.setData("cardInfo", JSON.stringify({ id, status }));
		e.target.className += " ohhold";
		setTimeout(() => {
		setOnHold(true);
		}, 0);
	};

	const dragEndHandler = () => {
		setOnHold(false);
	};

	const onDragOverHandler = (e) => {
		e.preventDefault();
		if (e.target.className === "card") {
			setTimeout(() => {
				e.target.className = "card anotherCardOnTop";
			}, 0);
		}
	};

	const onDragLeaveHandler = (e) => {
		resetClassName(e);
	};

	const onDropHandler = (e) => {
		resetClassName(e);
		/**  
		TODO: Remove all anotherCardOnTop classnames 
		from DOM after drop complete.
		*/
	};

	const resetClassName = (e) => {
		e.preventDefault();
		let isCard = e.target.className === "card" || e.target.className === "card anotherCardOnTop";
		
		if (isCard) {
			setTimeout(() => {
				e.target.className = "card";
			}, 0);
		}

	};


	const getFirstLatter = (title) => {

		//alert(title);
		//console.log(title);
		if(title){
			return title.charAt(0).toUpperCase();

		}
		return "N/A";

		
	};


	const bookingTime = (booking_date_time) => {

		//alert(title);
		//console.log(title);
		if(booking_date_time){
			return booking_datetime;
		}
		return "N/A";

		
	};

	const openModel = () => {
		
		console.log("open model");

	};


	return (
			<>
				<div className="snglCard" id={id}
					className={`card ${onHold ? "hidden" : ""}`}
					draggable="true"
					onDragStart={dragStartHandler}
					onDragEnd={dragEndHandler}
					onDragOver={onDragOverHandler}
					onDragLeave={onDragLeaveHandler}
					onDrop={onDropHandler}
					onClick={ ()=> showBookingDetails(id) }
					data-mdb-toggle="modal" 
					data-mdb-target="#exampleModal"
					>
						
					<div className="avtar">
						<div className="snglAvtar">
							<span data-toggle="tooltip" data-placement="top" title="" data-bs-original-title="Candis">{ getFirstLatter(title) }</span>
							<div className="fav">❤️</div>
						</div>
					</div>

					<div className="avtarDetails">
						<div className="statusIcon">
							<ul>
								<li data-toggle="tooltip" data-placement="top" title="" data-bs-original-title="Arriving Late">🐢</li>
								<li data-toggle="tooltip" data-placement="top" title="" data-bs-original-title="Confirmation Text Sent!">✅</li>
							</ul>
						</div>
						<div>
							<div className="date">{bookingTime(booking_datetime)}</div>
							<div className="cliName">{ Capitalize(title) }</div>
							
						</div>
					</div>
				</div>
			</>
		);
}
