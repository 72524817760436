import React from "react";
import { withRouter } from "react-router-dom";
import Header from "../../components/Header/Header";

const Sales = () => {
  return (
    <>
      <Header />
      <div>
        Integer quis nibh hendrerit, varius mi et, molestie eros. Integer sed
        scelerisque dui. Orci varius natoque penatibus et magnis dis parturient
        montes, nascetur ridiculus mus. Praesent fermentum mi quis ante
        imperdiet fringilla vestibulum id sem. Donec nec egestas leo. Curabitur
        ornare semper scelerisque. Fusce sit amet dictum leo. Vivamus pharetra
        nisi neque, ac venenatis lacus mollis pellentesque. Ut et fermentum
        sapien. Maecenas quam ex, eleifend et egestas nec, volutpat at lacus.
        Phasellus dignissim faucibus sapien in rhoncus. Sed vitae velit dapibus,
        hendrerit lorem ac, consectetur erat. Nam egestas vehicula sollicitudin.
        Donec luctus dolor porta est luctus, nec accumsan sem porttitor.
        Suspendisse potenti. Donec vulputate suscipit leo non maximus. Nullam
        commodo venenatis aliquam. Nulla non aliquam tortor. Mauris fringilla,
        sem vitae suscipit tempor, turpis tellus convallis risus, in tristique
        mi felis quis felis. Nam sed sem neque. Fusce at elit enim. Ut aliquet,
        odio sit amet pretium dignissim, quam libero pharetra mauris, a tempor
        risus sapien eu diam. Nullam velit neque, dignissim vel est vehicula,
        iaculis gravida lacus. Integer vitae nisi mauris. Nullam eu lobortis
        dui, ut mollis augue. Vivamus quis feugiat turpis, ac ullamcorper elit.
        Duis sapien elit, congue at imperdiet at, varius convallis lacus. Nulla
        facilisi. Pellentesque nibh mi, porttitor et eleifend non, tempor vitae
        massa. Etiam nulla libero, consectetur at mauris et, hendrerit dignissim
        nibh. Pellentesque vitae tempus dui, dignissim pellentesque augue.
      </div>
    </>
  );
};

export default withRouter(Sales);
