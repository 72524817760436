import React from "react";
import { withRouter } from "react-router-dom";
import Header from "../../components/Header/Header";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";

const Settings = (props) => {
  return (
    <React.Fragment>
      <Header />
      {/*<!-- Wrapper --> */}
      <div id="settings" className="mainWrapper">
        {/* <!-- Status Bar --> */}
        <div id="settingsBar" className="statusBar settingsBar container-fluid">
          <div className="leftSide pageTitle">
            <h4 className="barTitle">Settings</h4>
          </div>
          <div className="rightSide AddNew">
            <button
              className="btn btn-primary purple"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              data-bs-whatever="@mdo"
            >
              New Category
            </button>
            <button className="btn btn-primary purple ms-2">New Service</button>
          </div>
        </div>
        {/* <!--End of Status Bar --> */}
        <div
          id="setPanelWrap"
          className="setWrap panelWrap bodyWrap p-0 container-fluid"
        >
          <div className="row m-0 g-0 justify-content-end">
            <div id="left" className="serMenu leftSide sideBarMenu p-0 col">
              <LeftSidebar />
            </div>
            <div
              id="right"
              className="rightSide col panel serDetails setBodyDetails"
            >
             
            </div>
          </div>
        </div>
      </div>
      {/*<!-- Button trigger modal --> */}
      {/*<!-- Modal --> */}
    
      {/* End of Wrapper */}
    </React.Fragment>
  );
};

export default withRouter(Settings);
