import React, { useState, useEffect } from "react";
import axios from "axios";
//import { ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";
import Header from "../../components/Header/Header";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import CategoryList from "../../components/Category/CategoryList/CategoryList";
import AddUpdateModal from "../../components/Category/CategoryModals/AddCategoryModal";
import UpdateCategoryModal from "../../components/Category/CategoryModals/UpdateCategoryModal";
import DeleteCategoryModal from "../../components/Category/CategoryModals/DeleteCategoryModal";

const Category = (props) => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [categorydata, setCategorydata] = useState([]);

  const openModal = (data) => {
    //console.log("ddddddddd");
    setCategorydata(data);
  };

  const ListCategories = () => {
    axios.get(`${API_BASE_URL}/list-category`, HEADERS).then((res) => {
      setCategoriesList(res.data.categories);
    });
  };

  useEffect(() => {
    ListCategories();
  }, []);

  return (
    <React.Fragment>
      <Header />
      {/* <ToastContainer /> */}
      <div id="settings" className="mainWrapper">
        <div id="settingsBar" className="statusBar settingsBar container-fluid">
          <div className="leftSide pageTitle">
            <h4 className="barTitle">Manage Categoires</h4>
          </div>
          <div className="rightSide AddNew">
            <button
              className="btn btn-primary purple"
              data-bs-toggle="modal"
              data-bs-target="#AddCategoryModal"
              //data-bs-whatever="@mdo"
            >
              New Category
            </button>
          </div>
        </div>
        <div
          id="setPanelWrap"
          className="setWrap panelWrap bodyWrap p-0 container-fluid"
        >
          <div className="row m-0 g-0 justify-content-end">
            <div id="left" className="serMenu leftSide sideBarMenu p-0 col">
              <LeftSidebar />
            </div>
            <div
              id="right"
              className="rightSide col panel serDetails setBodyDetails"
            >
              <div className="row g-0">
                <div className="topPart col-12">
                  {/* <!-- End of Wrapper--> */}
                  <div className="row">
                    <div className="panelHead col mb-2">
                      <h3 className="mb-0">
                        <i className="fas fa-circle success"></i>Service
                        Categoies
                      </h3>
                    </div>
                    <div className="col staDroMenu text-end">
                      <button className="btn btn-simple p-0">
                        Active <i className="fas fa-chevron-down"></i>
                      </button>
                    </div>
                  </div>
                  <CategoryList
                    openModal={openModal}
                    categoriesList={categoriesList}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* add modal */}
        <AddUpdateModal ListCategories={ListCategories} />
        {/* update modal */}
        <UpdateCategoryModal
          category={categorydata}
          ListCategories={ListCategories}
        />
        {/* delete modal */}
        <DeleteCategoryModal
          modalId={categorydata.id}
          ListCategories={ListCategories}
        />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Category);
