import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { API_BASE_URL, HEADERS, UPLOAD_URL } from "../../Helper/ApiConstants";
import Select from "react-select";
const EmployeesEditModal = ({
							getEmployeeList,
							userData,
							staffrole,
							services,
							location}) => {
		

	const [logo, setLogo] = useState('');

	const initialFormState = {mySelectKey:null};
	const [myForm, setMyForm] = useState(initialFormState);


	const {
			register,
			handleSubmit:handleSubmit,
			handleSubmit : handleSubmit1,
			reset,
			control,
			formState: { errors },
			} = useForm({
				defaultValues: userData,
			});

	// const { 
	// 		handleSubmit : handleSubmit1,
	// 		} = useForm({});


	//const [bookRoomData, setBookRoomData] = useState([]);
	const [emp_services_prices, setEmpServicesPrices] = useState([]);

	const onImageChange = (e) => {
		const [file] = e.target.files;
		setLogo(URL.createObjectURL(file));
	};

	const removeServicePrice = (emp_services_prices_id) => { 

		axios
		.post(API_BASE_URL + "/remove-empservice-price", {emp_services_prices_id:emp_services_prices_id}, HEADERS)
		.then((response) => {
			if (response.status === 200) {
				toast.success("Employee Update successfully");
				getEmpServicePrices();
			}
		});

		console.log(emp_services_prices_id,"to delete");

	}
	const updateHandler = (data) => {
		console.log(data,"2222222222222222222");
		//setLogo(data.image[0].name);
		const formData = new FormData();
		formData.append("files", data.image[0]);
		formData.append("employee_id", data.employee_id);
		formData.append("first_name", data.first_name);
		formData.append("last_name", data.last_name);
		formData.append("username", data.username);
		formData.append("price", data.price);
		formData.append("email", data.email);
		formData.append("contact_number", data.contact_number);
		formData.append("staff_roles", data.staff_roles);
		formData.append("staff_locations", data.staff_locations);
		formData.append("emp_services", data.service);
		formData.append("emp_service_prices", data.service_price);
		/* formData.forEach((value, key) => {
		console.log(key + " " + value);
		}); */
		axios
		.post(API_BASE_URL + "/employee-update", formData, HEADERS)
		.then((response) => {
			if (response.status === 200) {
				toast.success("Employee Update successfully");
				getEmployeeList();
				reset();
			}
		});
	};

	const updateForm = value => {
		console.log(value);
		setMyForm({ ...myForm, mySelectKey: value });
	  };

	const AddUpdateStaffServicePrices = (data) => {
		//setLogo(data.image[0].name);
		const formData = new FormData();
		formData.append("employee_id", data.employee_id);
		formData.append("emp_services",myForm.mySelectKey);
		formData.append("emp_service_prices", data.services_price);
		/* formData.forEach((value, key) => {
		console.log(key + " " + value);
		}); */

		setMyForm(initialFormState);
		axios
		.post(API_BASE_URL + "/employee-service-price-addupdate", formData, HEADERS)
		.then((response) => {
			if (response.status === 200) {
				reset();
				toast.success("Employee Update successfully");
				getEmpServicePrices();
				
			}
		});
	};



	const updateStatus = (id) => {
		let emp_id = { emp_id: id };
		axios
		.post(API_BASE_URL + "/employee-status", emp_id, HEADERS)
		.then((response) => {
			if (response.status === 200) {
				toast.warning("Employee Deactivate");
				getEmployeeList();
			}
		});
	};

	

	// const handleAddFields = () => {
	// 	const values = [...bookRoomData];
	// 	values.push({service:'', service_prices:0})
	// 	setBookRoomData(values);
	// };
	// const handleRemoveFields = index => {
	// 	const values = [...bookRoomData];
	// 	if(values.length > 1)  values.pop();
	// 	setBookRoomData(values);
	// };



	const getEmpServicePrices = () => {

		let data = { emp_id:userData.employee_id }
		axios
			.post(`${API_BASE_URL}/employee-service-price-get`,data, HEADERS)
			.then((response) => {

				setEmpServicesPrices(response.data.staff_services_prices);
				//setServices(response.data.services);
			})
			.catch(function (error) {
				console.log(error);
			});
	};



	useEffect(() => {
		getEmpServicePrices();	
		reset(userData);

	}, [userData]);


	return (
		<>
			{/* Edit Emplyee */}
			<div className="modal modalWrap slidePanel fade" id="employeeEdits" aria-labelledby="exampleModalLabel">
				<div className="panel-dialog modal-dialog modal-dialog-scrollable panel-lg">
					<div className="panel-content modal-content">
						<div className="close-panel text-align-center align-middle">
							<button className="btn btn-simple p-0 white" data-bs-dismiss="modal" aria-label="Close" >
								<div className="fal fa-times"></div>
								Close
							</button>
						</div>
							
							<div className="panel-header">
								<h5 className="panel-title pb-0">{userData.first_name} {userData.last_name} </h5>
								<div className="tabOptions  panelTabs">
									<ul className="nav nav-tabs" id="myTab" role="tablist">
										<li className="nav-item" role="presentation">
											<a className="nav-link active" id="home-tab" data-bs-toggle="tab" href="#overview">Overview</a>
										</li>
										{/*<li className="nav-item" role="presentation">
											<a
												className="nav-link"
												id="profile-tab"
												data-bs-toggle="tab"
												href="#services"
												role="tab"
												aria-controls="profile"
												aria-selected="false"
												>
											Services
											</a>
										</li>
										<li className="nav-item" role="presentation">
											<a
												className="nav-link"
												id="profile-tab"
												data-bs-toggle="tab"
												href="#communication"
												role="tab"
												aria-controls="profile"
												aria-selected="false"
												>
											Communication
											</a>
										</li>*/}
										<li className="nav-item" role="presentation">
											<a className="nav-link" id="profile-tab" data-bs-toggle="tab" href="#compensation" role="tab" aria-controls="profile" aria-selected="false" >Compensation</a>
										</li>
									</ul>
								</div>
							</div>
							<div className="modal-body panelBody">
								<div className="tab-content tabContent" id="empDetails">
									<div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="home-tab">
										<div className="row mt-5">
											<form key={1}>

												<input id="d" type="hidden" {...register("employee_id") } />
												<div className="col-4 userPhoto text-center">
													<div className="userImg">
														<div className="form-group col-6 pe-2">

															<label htmlFor="image" className="form-label label">Logo<span>*</span></label>
															<input
																id="image"
																type="file"
																{...register("image")}
																required
																onChange={onImageChange}
															/>
														</div>
														<div className="form-group col-6 pe-2">
															{logo && (
															<img
																src={logo}
																id="location_logo_img"
																alt=""
																className="location_logo_img"
																/>
															)}
														</div>
														<img src={UPLOAD_URL + `/${userData.profile_img}`} alt="userImage" />
														{/* <button className="btn btn-primary purple uploadBtn">
														<i className="fas fa-upload"></i>
														</button> */}
													</div>
												</div>
												<div className="col-8 pric">
													<div className="row g-0 mt-3">
														<div className="form-group col-lg-6 col-sm-12 pe-3 mb-5">
															<label htmlFor="first_name" className="form-label label">First Name<span>*</span>
															</label>
															<input
																type="text"
																className="form-control"
																id="first_name"
																{...register("first_name", {
																required: "FirstName is Required",
																})}
															/>
															{errors.first_name && (
																<span className="text-danger">
																	{errors.first_name.message}
																</span>
															)}
														</div>
														<div className="form-group col-lg-6 col-sm-12 pe-3 mb-5">
															<label htmlFor="last_name" className="form-label label" >Last Name<span>*</span>
															</label>
															<input
																type="text"
																className="form-control"
																id="last_name"
																{...register("last_name", {
																required: "LastName is Required",
																})}
															/>
															{errors.last_name && (
																<span className="text-danger">
																{errors.last_name.message}
																</span>
															)}
														</div>
														{/* 
														<div className="form-group col-lg-12 col-sm-12 pe-3 mb-5">
															<label htmlFor="" className="form-label label">
															Alias Name
															</label>
															<input
																type="text"
																className="form-control"
																name=""
																//value=""
																/>
														</div>
														*/}
													</div>
												</div>
												<div className="col-12 contInfo">
													<div className="row g-0 mb-5">
														<div className="col-lg-12 col-sm-12 pe-3 mb-3">
															<h5 className="bigLabel text-purple mb-3">Contact Info</h5>
														</div>
														<div className="form-group col-lg-6 col-sm-12 pe-3">
															<label htmlFor="contact_number" className="form-label label">Mobile</label>
															<input
															type="text"
															className="form-control"
															id="contact_number"
															{...register("contact_number", {
															required: "Contact is Required",
															// pattern: {
															//   //value: /^[0-9]*$/,
															//   value: /[0-9]{3}-[0-9]{3}-[0-9]{4}/,
															//   message: "Please Enter Numbers Only",
															// },
															})}
															/>
															{errors.contact_number && (
															<span className="text-danger">
															{errors.contact_number.message}
															</span>
															)}
														</div>
														<div className="form-group col-lg-6 col-sm-12 pe-3">
															<label htmlFor="email" className="form-label label">Email</label>
															<input
															type="email"
															className="form-control"
															id="email"
															{...register("email", {
															required: "Email is Required",
															})}
															/>
															{errors.email && (
															<span className="text-danger">
															{errors.email.message}
															</span>
															)}
														</div>
													</div>
													{/* 
													<div className="row g-0 mb-3">
														<div className="form-check checkbox col-lg-12 pe-3">
															<input
															className="form-check-input"
															type="checkbox"
															id="bookable_online"
															defaultChecked
															{...register("bookable_online")}
															/>
															<label className="form-check-label" htmlFor="bowery">
															<strong>Bookable Online</strong>
															</label>
														</div>
													</div>
													*/}
												</div>
												<div className="col-12 calDisplay">
													<div className="row">
														<div className="form-group col-12">
															<label htmlFor="username" className="form-label label">Internal Display Name <span>*</span>
															</label>
															<input
															type="text"
															className="form-control"
															id="username"
															{...register("username", {
															required: "Username is Required",
															})}
															/>
															{errors.username && (
															<span className="text-danger">
															{errors.username.message}
															</span>
															)}
														</div>
														{/*
														<div className="form-group col-6">
															<label htmlFor="price" className="form-label label">Service Charges <span>*</span></label>
															<input
															type="text"
															className="form-control"
															id="price"
															{...register("price", {
															required: "Price is Required",
															})}
															/>
															{errors.price && (
															<span className="text-danger">{errors.price.message}</span>
															)}
														</div>
														*/}
													</div>
												</div>
												<div className="col-12 calDisplay">
													<div className="row g-0 mb-4">
														<div className="col-lg-12 col-sm-12 pe-3 mb-3">
															<h5 className="bigLabel text-purple">Staff Role</h5>
														</div>
														<div className="form-group col-lg-12 pe-3">
															<Controller
															name="staff_roles"
															id="staff_roles"
															control={control}
															render={({ field: { onChange, value, ref } }) => (
															<Select
																options={staffrole}
																value={staffrole.find((c) => c.value === value)}
															id="staff_roles1"
															onChange={(val) => onChange(val.value)}
															/>
															)}
															rules={{ required: "This is required" }}
															/>
														</div>
													</div>
												</div>
												<div className="col-12 calDisplay">
													{/*<div className="row g-0 mb-4">
														<div className="col-lg-12 col-sm-12 pe-3">
															<h5 className="bigLabel text-purple">Biography</h5>
														</div>
														<div className="form-group col-lg-12 pe-3">
															<textarea
															className="form-control"
															rows="4"
															placeholder="Enter you Bio.."
															{...register("biography", {
															required: "Staff role is Required",
															})}
															/>
															{errors.biography && (
															<span className="text-danger">{errors.biography.message}</span>
															)}
														</div>
													</div>*/}
													<div className="row g-0 mb-4">
														<div className="col-lg-12 col-sm-12 pe-3">
															<h5 className="bigLabel text-purple">Locations</h5>
														</div>
														<div className="col-lg-12 pe-3">
															<Controller
																control={control}
																id="locations"
																defaultValue={location.map((c) =>c.value)}
																name="staff_locations"
																render={({ field: { onChange, value, ref } }) => (
																	<Select
																		inputRef={ref}
																		value={location.filter((c) =>value.includes(c.value))}
																		onChange={(val) =>onChange(val.map((c) => c.value))}
																		options={location}
																		key={location.map((c) => c.value)}
																		isMulti
																	/>
																)}
															/>
														</div>
													</div>
												</div>
												<div className="row g-0">
													<div className="col">
														<button className="btn btn-danger" onClick={() => updateStatus(userData.employee_id)}>Deactivate</button>
													</div>
													<div className="col text-end">
														<button className="btn btn-primary" type="button" onClick={handleSubmit(updateHandler)}>Save Changes</button>
													</div>
												</div>

											</form>

										</div>
									</div>
									<div className="tab-pane fade" id="compensation" role="tabpanel" aria-labelledby="home-tab">
										<form key={2}>
											<input type="hidden" {...register("employee_id")} />
											<div className="row mt-5">
												<div className="col-sm-10 panel">
													<h5 className="text">Alejandra has no pay rates added yet.</h5>
													<p>
														<small>
														Future changes to Alejandra's pay rates should be
														added as new entries. You can edit historical rates
														for record-keeping purposes.
														</small>
													</p>

													{/*<button className="btn btn-primary purple btn-lg">+ Add a pay rate</button>*/}

													<div className="showprice-fields">
													
														<div className="row g-0" >
															<div className="col">
																<h5 className="bigLabel">Select Service</h5>
																<div className="form-group col-lg-12 pe-3">
																	<Controller
																		name= "services"
																		id={"services"}
																		control={control}
																		render={({ field: { onChange, value, ref } }) => (
																			<Select
																				options={services}
																				//value={services.find((c) => c.value === value)}
																				value={services.filter(({ value }) => value === myForm.mySelectKey)}
																				id={"services"}
																				onChange={({ value }) => updateForm(value)}
																				//onChange={(val) => onChange(val.value)}
																			/>
																		)}

																		//rules={{ required: "This is required" }}
																	/>
																</div>
															</div>
															<div className="col">
																<h5 className="bigLabel">Price</h5>
																<div className="form-group">
																	<input
																		name="services_price"
																		type="text"
																		placeholder="Service Charges"
																		className="form-control"
																		id={"price"}
																		{...register("services_price")}
																	/>
																</div>
															</div>
															<div className="col text-end">
																<button type="submit" className="btn btn-primary" 
																	onClick={ handleSubmit1(AddUpdateStaffServicePrices) }
																	>Save Changes</button>
															</div>
														</div>	

														<div className="row g-0">
															<div className="col"><h5>Service</h5></div>
															<div className="col"><h5>Price</h5></div>
														</div> 

														{ emp_services_prices.map(( emp_service_price,i ) => {

																return ( 
																	<div className="row g-0" key={"key_"+i}>

																		
																		<div className="col">
																			<div className="form-group col-lg-12 pe-3">
																				{emp_service_price.service_title}
																			</div>
																		</div>
																		<div className="col">
																			<div className="form-group">
																				${emp_service_price.service_prices}

																			</div>
																		</div>
																		<div className="col">
																			<button type="button" className="btn btn-danger btn-small" onClick={ ()=> removeServicePrice(emp_service_price.id)} ><i className="fa fa-trash" aria-hidden="true" /></button>
																		</div> 

																	</div> 
																)
															})	
														}


														
													</div>
												</div>

											</div>
											
										</form>
									</div>
									{/*<div
										className="tab-pane fade"
										id="services"
										role="tabpanel"
										aria-labelledby="home-tab"
										>
										<div className="row mt-5">
											<div className="col-sm-5 panel">
												<h5 className="text-purple">Fox and Jane Salon (Upper West Side)</h5>
											</div>
										</div>
										<div className="row g-0 mt-3 empSerDetails">
											<div className="col-sm-12 panel">
												<table className="panelTable table-sm table-hover table">
													<thead>
														<tr>
															<th className="col-3">Smoothing and texture treatments</th>
															<th></th>
															<th>Price</th>
															<th>Duration</th>
															<th>Processing Time</th>
															<th>Finishing Time</th>
															<th>Transition Time</th>
															<th>Business Charge</th>
															<th>Commission Percentage</th>
															<th></th>
														</tr>
													</thead>
													<tbody>
														<tr className="notAssign">
															<td>Fringe Smoothing</td>
															<td>Not Assignable</td>
															<td>$50.00</td>
															<td>1 hr</td>
															<td>-</td>
															<td>-</td>
															<td>-</td>
															<td>$0.00</td>
															<td>Pay Rate Default</td>
															<td className="">
																<button className="btn btn-default border white btn-sm">Customize</button>
															</td>
														</tr>
														<tr className="notAssign">
															<td>Keratin Smoothing Treatment</td>
															<td>Not Assignable</td>
															<td>$310.00</td>
															<td>2.30 hrs</td>
															<td>-</td>
															<td>-</td>
															<td>5 mins</td>
															<td>$0.00</td>
															<td>Pay Rate Default</td>
															<td className="">
																<button className="btn btn-default border white btn-sm">Customize</button>
															</td>
														</tr>
														<tr className="notAssign">
															<td>The American Wave</td>
															<td>Not Assignable</td>
															<td>$415.00</td>
															<td>3.30 hrs</td>
															<td>-</td>
															<td>-</td>
															<td>5 mins</td>
															<td>$0.00</td>
															<td>Pay Rate Default</td>
															<td className="">
																<button className="btn btn-default border white btn-sm">Customize</button>
															</td>
														</tr>
														<tr className="notAssign">
															<td>Magic Sleek</td>
															<td>Not Assignable</td>
															<td>$350.00</td>
															<td>2 hrs</td>
															<td>-</td>
															<td>-</td>
															<td>-</td>
															<td>$0.00</td>
															<td>Pay Rate Default</td>
															<td className="">
																<button className="btn btn-default border white btn-sm">Customize</button>
															</td>
														</tr>
													</tbody>
													<thead>
														<tr>
															<th colSpan="10">Treatments</th>
														</tr>
													</thead>
													<tbody>
														<tr className="assigned">
															<td>The American Wave</td>
															<td className="text-purple">Assignable</td>
															<td>$415.00</td>
															<td>3.30 hrs</td>
															<td>-</td>
															<td>-</td>
															<td>5 mins</td>
															<td>$0.00</td>
															<td>Pay Rate Default</td>
															<td className="">
																<button className="btn btn-default border white btn-sm">
																Customize
																</button>
															</td>
														</tr>
														<tr className="assigned">
															<td>Magic Sleek</td>
															<td className="text-purple">Assignable</td>
															<td>$350.00</td>
															<td>2 hrs</td>
															<td>-</td>
															<td>-</td>
															<td>-</td>
															<td>$0.00</td>
															<td>Pay Rate Default</td>
															<td className="">
																<button className="btn btn-default border white btn-sm">
																Customize
																</button>
															</td>
														</tr>
														<tr className="assigned">
															<td>Brazilian Blowout</td>
															<td className="text-purple">Assignable</td>
															<td>$0.00</td>
															<td>2 hr</td>
															<td>-</td>
															<td>-</td>
															<td>-</td>
															<td>$0.00</td>
															<td>Pay Rate Default</td>
															<td className="">
																<button className="btn btn-default border white btn-sm">
																Customize
																</button>
															</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div
										className="tab-pane fade"
										id="communication"
										role="tabpanel"
										aria-labelledby="home-tab"
										>
										<div className="row mt-5">
											<div className="col-sm-4 panel">
												<h5 className="text-purple">
													Communication Preferences
													<small>
													Rest easy knowing you'll receive immediate
													notifications of your clients' actions. You may
													customize how you would like to be notified from
													Boulevard.
													</small>
												</h5>
											</div>
										</div>
										<div className="row g-o mt-5 empCommunication">
											<div className="col-sm-10 panel ">
												<div className="row g-0">
													<div className="col">
														<h5 className="bigLabel">
															New Online Booking
															<small>
															Sent when a client self-books an appointment.
															</small>
														</h5>
													</div>
													<div className="col">
														<div className="form-check form-switch">
															<input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
															<label className="form-check-label" htmlFor="flexSwitchCheckDefault" >Receive Email</label>
														</div>
														<div className="form-check form-switch mt-4">
															<input className="form-check-input" type="checkbox" id="receTextMsg"/>
															<label className="form-check-label" htmlFor="receTextMsg">Receive Text Message</label>
														</div>
														<div className="form-check form-switch mt-4">
															<input className="form-check-input" type="checkbox" id="receTextMsg"/>
															<label className="form-check-label" htmlFor="receTextMsg">Receive Text Message</label>	
														</div>
													</div>
												</div>
											</div>
											<div className="col-sm-10 panel mt-5">
												<div className="row g-0">
													<div className="col">
														<h5 className="bigLabel">
															New Front Desk Booking
															<small>
															Sent when an appointment is added to your
															calendar.
															</small>
														</h5>
													</div>
													<div className="col">
														<div className="form-check form-switch">
															<input className="form-check-input" type="checkbox" id=""/>
															<label className="form-check-label " htmlFor="">Receive Email</label>
														</div>
														<div className="form-check form-switch mt-4">
															<input className="form-check-input" type="checkbox" id="front-receTextMsg"/>
															<label className="form-check-label " htmlFor="front-receTextMsg"> Receive Text Message</label>
														</div>
														<div className="form-check form-switch mt-4">
															<input className="form-check-input" type="checkbox" id="front-pushNoti"/>
															<label className="form-check-label " htmlFor="front-pushNoti">Receive Push Notification</label>
														</div>
													</div>
												</div>
											</div>
											<div className="col-sm-10 panel mt-5">
												<div className="row g-0">
													<div className="col">
														<h5 className="bigLabel">Client Arrival
															<small>Sent when a client arrives for an appointment.</small>
														</h5>
													</div>
													<div className="col">
														<div className="form-check form-switch">
															<input
																className="form-check-input"
																type="checkbox"
																id="client-flexSwitchCheckDefault"
																/>
															<label
																className="form-check-label "
																htmlFor="client-front-flexSwitchCheckDefault"
																>
															Receive Email
															</label>
														</div>
														<div className="form-check form-switch mt-4">
															<input
																className="form-check-input"
																type="checkbox"
																id="client-front-receTextMsg"
																defaultChecked
																/>
															<label className="form-check-label" htmlFor="client-front-receTextMsg"> 								Receive Text Message
															</label>
														</div>
														<div className="form-check form-switch mt-4">
															<input
																className="form-check-input"
																type="checkbox"
																id="front-pushNoti"
																defaultChecked
																/>
															<label
																className="form-check-label "
																htmlFor="client-pushNoti"
																>
															Receive Push Notification
															</label>
														</div>
													</div>
												</div>
											</div>
											<div className="col-sm-10 panel mt-5 mb-5">
												<div className="row g-0">
													<div className="col">
														<h5 className="bigLabel">
															Service Order Completed
															<small>
															Sent when a client arrives for an appointment.
															</small>
														</h5>
													</div>
													<div className="col">
														<div className="form-check form-switch mt-4">
															<input className="form-check-input" type="checkbox"
																id="Order-pushNoti" defaultChecked/>
															<label className="form-check-label" htmlFor="Order-pushNoti"
																>
															Receive Push Notification
															</label>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>*/}
									
								</div>
							</div>
						
					</div>
				</div>
			</div>
			{/* End Edit Emplyee */}
		</>
	);
};
export default EmployeesEditModal;