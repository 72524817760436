import React from "react";

const SupplierList = ({ supplier, openModal }) => {
  return (
    <div className="row g-0 mt-2">
      <div className="col panel serblock p-0">
        <table className="panelTable shadow table fixed-layout table-hover">
          <thead>
            <tr>
              <th className="name">
                Name <i className="far fa-arrow-up"></i>
              </th>
              <th className="phoNumber">Phone #</th>
              <th className="email">Email</th>
              <th className="email">Action</th>
            </tr>
          </thead>
          <tbody>
            {supplier.map((supplier, index) => (
              <tr
                key={index}
                data-bs-toggle="modal"
                data-bs-target="#editSupplier"
                onClick={() => openModal(supplier)}
              >
                <td>{supplier.supplier_name}</td>
                <td>{supplier.supplier_contact}</td>
                <td>{supplier.supplier_email}</td>
                <td>
                  <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target={`#ModalDelete`}
                    onClick={() => openModal(supplier)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SupplierList;
