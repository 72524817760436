import React, { useEffect, useState } from "react";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import axios from "axios";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";
import { withRouter } from "react-router-dom";
import Header from "../../components/Header/Header";
import SupplierList from "../../components/Supplier/SupplierList/SupplierList";
import SupplierDeleteModal from "../../components/Supplier/SupplierModal/SupplierDeleteModal";
import SupplierEditModal from "../../components/Supplier/SupplierModal/SupplierEditModal";
import SupplierAddModal from "../../components/Supplier/SupplierModal/SupplierAddModal";

const Supplier = () => {
  const [supplier, setSupplier] = useState([]);
  const [supliredata, setSupliredata] = useState([]);

  const openModal = (data) => {
    //console.log("ddd", data);
    setSupliredata(data);
  };

  //Get product list
  const getSupplierList = () => {
    axios
      .get(`${API_BASE_URL}/supplier-list`, HEADERS)
      .then(function (response) {
        if (response.status === 200) {
          setSupplier(response.data);
        }
      });
  };

  useEffect(() => {
    getSupplierList();
  }, []);

  return (
    <>
      <Header />
      {/* <!-- Wrapper --> */}
      <div id="settings" className="mainWrapper">
        {/* <!-- Status Bar --> */}
        <div id="settingsBar" className="statusBar settingsBar container-fluid">
          <div className="leftSide pageTitle">
            <h4 className="barTitle">Manage Business</h4>
          </div>
          <div className="rightSide AddNew">
            <button
              className="btn btn-primary purple"
              data-bs-toggle="modal"
              data-bs-target="#newSupplier"
            >
              New Supplier
            </button>
          </div>
        </div>
        {/* <!--End of Status Bar --> */}
        <div
          id="setPanelWrap"
          className="setWrap panelWrap bodyWrap p-0 container-fluid"
        >
          <div className="row m-0 g-0 justify-content-end">
            <div id="left" className="serMenu leftSide sideBarMenu p-0 col">
              <LeftSidebar />
            </div>
            <div
              id="right"
              className="rightSide col panel serDetails setBodyDetails"
            >
              <div className="row g-0">
                <div className="topPart col-12">
                  {/*
						<!-- End of Wrapper-->*/}
                  <div className="row">
                    <div className="panelHead col mb-2">
                      <h3 className="mb-0">
                        <i className="fas fa-circle success"></i> Active
                        Suppliers
                      </h3>
                    </div>
                    <div className="col staDroMenu text-end">
                      <button className="btn btn-simple p-0">
                        Active <i className="fas fa-chevron-down"></i>
                      </button>
                    </div>
                  </div>
                  <SupplierList supplier={supplier} openModal={openModal} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* add supplier modal */}
      <SupplierAddModal getSupplierList={getSupplierList} />

      {/* delete supplier modal */}
      <SupplierDeleteModal
        supplier_id={supliredata.id}
        getSupplierList={getSupplierList}
      />

      {/* edit supplier modal */}
      <SupplierEditModal
        supplier={supliredata}
        getSupplierList={getSupplierList}
      />
    </>
  );
};
export default withRouter(Supplier);
