import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";
import { withRouter } from "react-router-dom";
//import { ToastContainer } from "react-toastify";
import Header from "../../components/Header/Header";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import AddonsList from "../../components/Add-ons/AddonsList";
import EditAddonsModal from "../../components/Add-ons/EditAddonsModal";
import AddAddonsModal from "../../components/Add-ons/AddAddonsModal";
import DeleteAddonsModal from "../../components/Add-ons/DeleteAddonsModal";

const Addons = () => {
	/* all add-ons */
	const [addons, setAddons] = useState([]);
	/* single add-ons */
	const [singleAddon, setSingleAddon] = useState([]);

	//Get Roles list
	const ListAddon = () => {
		axios
			.get(API_BASE_URL + "/addons-list", HEADERS)
			.then(function (response) {
				if (response.status === 200) {
					setAddons(response.data);
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const openModal = (data) => {
		setSingleAddon(data);
	};

	useEffect(() => {
		ListAddon();
	}, []);

	return (
		<React.Fragment>
			<Header />
			{/* <ToastContainer /> */}
			{/*<!-- Wrapper --> */}
			<div id="settings" className="mainWrapper">
				{/* <!-- Status Bar --> */}
				<div id="settingsBar" className="statusBar settingsBar container-fluid">
					<div className="leftSide pageTitle">
						<h4 className="barTitle">Manage Add-ons</h4>
					</div>
					<div className="rightSide AddNewDiscount">
						<button
							className="btn btn-primary purple"
							data-bs-toggle="modal"
							data-bs-target="#addNewAddons"
						>
							Add
						</button>
					</div>
				</div>
				{/* <!--End of Status Bar --> */}
				<div
					id="setPanelWrap"
					className="setWrap panelWrap bodyWrap p-0 container-fluid"
				>
					<div className="row m-0 g-0 justify-content-end">
						<div id="left" className="serMenu leftSide sideBarMenu p-0 col">
							<LeftSidebar />
						</div>
						<div
							id="right"
							className="rightSide col panel busiDetails setBodyDetails"
						>
							<div className="row g-0">
								<div className="topPart col-12">
									<div className="row align-items-center">
										<div className="panelHead col">
											<h3 className="mb-0">
												<i className="fas fa-circle success"></i> Active Add-ons
											</h3>
										</div>
										{/*<div className="col-3 right filterSearch staffInvite text-end">
											<button className="btn btn-simple p-0">
												Active<i className="fas fa-chevron-down"></i>
											</button>
											<button className="btn btn-simple p-0 ms-3 me-3">
												<i className="far fa-search"></i>
											</button>
											<button className="btn btn-default">Invite Staff</button>
										</div>*/}
									</div>
								</div>
								<div className="middlePart mt-3 col-12">
									<div className="row g-0 mt-2">
										<div className="col panel serblock p-0">
											<AddonsList addons={addons} openModal={openModal} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* add modal */}
			<AddAddonsModal ListAddon={ListAddon} />
			{/* edit modal */}
			<EditAddonsModal singleAddon={singleAddon} ListAddon={ListAddon} />
			{/* delete modal */}
			<DeleteAddonsModal modalId={singleAddon.id} ListAddon={ListAddon} />
			{/* End of Wrapper */}
		</React.Fragment>
	);
};

export default withRouter(Addons);
