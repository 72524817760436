import React, { useState, useEffect } from "react";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";
import axios from "axios";
// import { ToastContainer } from "react-toastify";
import { withRouter } from "react-router-dom";
import ServicesList from "../../components/ServicesList/ServicesList";
import AddServiceModal from "../../components/ServicesList/AddServiceModal";
import EditServiceModal from "../../components/ServicesList/EditServiceModal";
import Header from "../../components/Header/Header";
const Services = (props) => {
	/* get all service list */
	const [categoriesList, setCategoriesList] = useState([]);
	/* all locations */
	const [storeLocations, setStoreLocations] = useState([]);
	/* all addons list */
	const [addonsList, setAddonsList] = useState([]);
	/* all service */
	const [allService, setAllService] = useState([]);
	/* single service */
	const [singleService, setSingleService] = useState([]);

	//console.log(categoriesList);

	const openModal = (data) => {
		const locations = [];
		const dd = data.service_locations;

		if (dd) {
			for (let val of dd) {
				locations.push(val.location_service);
				//console.log(val);
			}
		}

		let newData = { ...data, service_locations: locations };

		const addons = [];
		const x = data.service_addons;

		if (x) {
			for (let val of x) {
				addons.push(val.addons_service);
				//console.log(val);
			}
		}

		newData = { ...newData, service_addons: addons };

		setSingleService(newData);
	};

	const ListServices = async () => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/list-services`,
				HEADERS
			);
			setCategoriesList(response.data.categories);
		} catch (error) {
			console.error(error);
		}
	};

	const listStoreLocation = async () => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/stores-locations-dropdown`,
				HEADERS
			);
			setStoreLocations(response.data.stores);
		} catch (error) {
			console.log(error);
		}
	};

	const allServiceList = () => {
		axios
			.get(API_BASE_URL + "/list-categories-dropdown", HEADERS)
			.then((response) => {
				if (response.status === 200) {
					setAllService(response.data.categories);
					//console.log(response.data.stores);
				}
			});
	};

	const allAddonsList = () => {
		axios
			.get(API_BASE_URL + "/addons-dropdown-list", HEADERS)
			.then((response) => {
				if (response.status === 200) {
					setAddonsList(response.data.addons);
					console.log(response.data.addons);
				}
			});
	};

	useEffect(() => {
		ListServices();
		listStoreLocation();
		allServiceList();
		allAddonsList();
	}, []);

	return (
		<React.Fragment>
			<Header />
			{/* <ToastContainer /> */}
			<div id="settings" className="mainWrapper">
				<div id="settingsBar" className="statusBar settingsBar container-fluid">
					<div className="leftSide pageTitle">
						<h4 className="barTitle">Services</h4>
					</div>
					<div className="rightSide AddNew">
						{/* <button className="btn btn-primary purple" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo">New Category</button> */}
						<button
							className="btn btn-primary purple ms-2"
							data-bs-toggle="modal"
							data-bs-target="#addNewService"
						>
							New Service
						</button>
					</div>
				</div>
				<div
					id="setPanelWrap"
					className="setWrap panelWrap bodyWrap p-0 container-fluid"
				>
					<div className="row m-0 g-0 justify-content-end">
						<div id="left" className="serMenu leftSide sideBarMenu p-0 col">
							<LeftSidebar />
						</div>
						<div id="right" className="rightSide col panel serDetails setBodyDetails">
							<div className="row g-0">
								<div className="topPart col-12">
									<div className="row">
										<div className="panelHead col mb-2">
											<h3 className="mb-0">
												<i className="fas fa-circle success"></i> Active Services
											</h3>
										</div>
										<div className="col staDroMenu text-end">
											<button className="btn btn-simple p-0">
												Active <i className="fas fa-chevron-down"></i>
											</button>
										</div>
									</div>

									<ServicesList
										categoriesList={categoriesList}
										openModal={openModal}
										//ListServices={ListServices}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AddServiceModal
				categoriesList={categoriesList}
				storeLocations={storeLocations}
				ListServices={ListServices}
				allService={allService}
				addonsList={addonsList}
			/>
			<EditServiceModal
				categoriesList={categoriesList}
				storeLocations={storeLocations}
				ListServices={ListServices}
				singleService={singleService}
				allService={allService}
				addonsList={addonsList}
			/>
		</React.Fragment>
	);
};

export default withRouter(Services);
