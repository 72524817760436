import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";
import Select from "react-select";

const EditServiceModal = ({
	singleService,
	storeLocations,
	ListServices,
	allService,
	addonsList,
}) => {
	const {
		register,
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		defaultValues: singleService,
	});

	const updateHandler = (data) => {

		axios
			.post(`${API_BASE_URL}/update-service`, data, HEADERS)
			.then((response) => {

				if (response.status === 200) {
					toast.success("Service Update successfully");
					
					ListServices();
					reset();
				}
			});
	};


	const updateStatus = (id, status) => {
		let service_data = { service_id: id, status:status };
		axios
			.post(`${API_BASE_URL}/update-service-status`, service_data, HEADERS)
			.then((response) => {
				if (response.status === 200) {
					toast.warning("Service Deactivate");
					ListServices();
				}
			});
	};

	useEffect(() => {
		reset(singleService);
	}, [singleService]);

	return (
		<div
			className="modal modalWrap slidePanel fade"
			id="inSalon"
			tabIndex="-1"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<div className="panel-dialog modal-dialog modal-dialog-scrollable panel-lg">
				<div className="panel-content modal-content">
					<div className="close-panel text-align-center align-middle">
						<button
							className="btn btn-simple p-0 white"
							data-bs-dismiss="modal"
							aria-label="Close"
						>
							<div className="fal fa-times"></div> Close
						</button>
					</div>
					<div className="panel-header">
						<h5 className="panel-title pb-0">In Salon Consulation</h5>
						<div className="tabOptions  panelTabs">
							<ul className="nav nav-tabs" id="myTab" role="tablist">
								<li className="nav-item" role="presentation">
									<a
										className="nav-link active"
										id="home-tab"
										data-bs-toggle="tab"
										href="#overview"
										role="tab"
										aria-controls="home"
										aria-selected="true"
									>
										Overview
									</a>
								</li>
								<li className="nav-item" role="presentation">
									<a
										className="nav-link"
										id="profile-tab"
										data-bs-toggle="tab"
										href="#options"
										role="tab"
										aria-controls="profile"
										aria-selected="false"
									>
										Options
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div className="modal-body panelBody">
						<div className="tab-content tabContent" id="myTabContent">
							<div
								className="tab-pane fade show active"
								id="overview"
								role="tabpanel"
								aria-labelledby="home-tab"
							>
								<div className="row mt-5">
									<div className="col-6 descr">
										<div className="form-group col-12 mb-5">
											<label htmlFor="" className="form-label label">
												Name<span>*</span>
											</label>
											<input
												type="text"
												className="form-control"
												placeholder="Ex. Hair Cut"
												{...register("service_title", {
													required: "service name is required",
												})}
											/>
											{errors.service_title && (
												<span className="text-danger">
													{errors.service_title.message}
												</span>
											)}
										</div>
										<div className="form-group col-12 mb-5">
											<label htmlFor="" className="form-label label">
												Category<span>*</span>
											</label>
											<Controller
												name="category_id"
												control={control}
												className="mdb-select md-form form-select"
												render={({ field: { onChange, value, ref } }) => (
													<Select
														inputRef={ref}
														isSearchable={true}
														options={allService}
														value={allService.find((c) => c.value === value)}
														onChange={(val) => onChange(val.value)}
													/>
												)}
												rules={{ required: "This is required" }}
											/>
											{errors.category_id && (
												<span className="text-danger">
													{errors.category_id.message}
												</span>
											)}
										</div>
										<div className="form-group col-12 mb-3">
											<label htmlFor="" className="form-label label">
												Description<span>*</span>
											</label>
											<textarea
												className="form-control"
												rows="5"
												cols="0"
												{...register("service_description", {
													required: "service name is required",
												})}
											/>
											{errors.service_description && (
												<span className="text-danger">
													{errors.service_description.message}
												</span>
											)}
										</div>
										{/*<div className="form-check col-12 form-switch">
											<input
												className="form-check-input"
												type="checkbox"
												id="flexSwitchCheckDefault"
												{...register("book_other_services")}
											/>
											<label
												className="form-check-label small"
												htmlFor="flexSwitchCheckDefault"
											>
												<strong>Default switch checkbox input</strong>
												(recommended) <br />
												Checking this box allows clients to book other services
												during the processing time of this service.
											</label>
										</div>*/}
									</div>
									<div className="col-5 pric">
										<div className="row g-0">
											<div className="form-group col-lg-6 col-sm-12 pe-3 mb-5">
												<label htmlFor="" className="form-label label">
													Default Price<span>*</span>
												</label>
												<input
													type="text"
													className="form-control"
													{...register("service_price", {
														required: "services price is required",
													})}
												/>
												{errors.service_price && (
													<span className="text-danger">
														{errors.service_price.message}
													</span>
												)}
											</div>
										</div>
										<div className="row g-0">
											<div className="form-group col-lg-6 col-sm-12 pe-3 mb-5">
												<label htmlFor="" className="form-label label">Duration<span>*</span></label>
												<input
													type="text"
													className="form-control"
													{...register("duration")}
												/>
											</div>
											<div className="form-group col-lg-6 col-sm-12 pe-3 mb-5">
												<label htmlFor="" className="form-label label">Processing Time<span>*</span></label>
												<input
													type="text"
													className="form-control"
													{...register("processing_time")}
												/>
											</div>
											<div className="form-group col-lg-6 col-sm-12 pe-3 mb-3">
												<label htmlFor="" className="form-label label">Finishing Time<span>*</span></label>
												<input
													type="text"
													className="form-control"
													{...register("finishing_time")}
												/>
											</div>
											<div className="form-group col-lg-6 col-sm-12 pe-3 mb-3">
												<label htmlFor="" className="form-label label">
													Transition Time<span>*</span>
												</label>
												<input
													type="text"
													className="form-control"
													{...register("transition_time")}
												/>
											</div>
											<div className="col-10 detailDesr">
												<p>
													<small>Durations and prices set at the business may be overridden at each location, for each staff role, 
													or for each staff member individually.</small>
												</p>
												<p>
													<small><strong>Processing Time :</strong>A block of time at the end of the service when a client is busy, but
														the staff can attend other clients. Generally used for hair color processing.</small>
												</p>
												<p>
													<small>
														<strong>Finishing Time :</strong>A block of time at
														the end of a service and any processing or
														transition time when both the client and the staff
														are busy.
													</small>
												</p>
												<p>
													<small>
														<strong>Transition Time :</strong> A block of time
														after the service is performed during which the
														staff member is unavailable. Use this for cleanup,
														buffer, documentation time, break time, etc.
													</small>
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="panel advLocaSetting mt-5">
									<div className="row g-0">
										<h5 className="bigLabel">Addons Services</h5>

										<Controller
											control={control}
											defaultValue={addonsList.map((c) => c.value)}
											name="service_addons"
											render={({ field: { onChange, value, ref } }) => (
												<Select
													inputRef={ref}
													value={addonsList.filter((c) =>
														value.includes(c.value)
													)}
													onChange={(val) => onChange(val.map((c) => c.value))}
													options={addonsList}
													isMulti
												/>
											)}
										/>
										{errors.service_addons && (
											<span className="text-danger">
												{errors.service_addons.message}
											</span>
										)}
									</div>
								</div>
								<div className="panel advLocaSetting mt-5">
									<div className="row g-0">
										<h5 className="bigLabel">Advanced Location Settings</h5>

										<Controller
											control={control}
											id="locations"
											defaultValue={storeLocations.map((c) => c.value)}
											name="service_locations"
											render={({ field: { onChange, value, ref } }) => (
												<Select
													inputRef={ref}
													value={storeLocations.filter((c) =>
														value.includes(c.value)
													)}
													onChange={(val) => onChange(val.map((c) => c.value))}
													options={storeLocations}
													isMulti
												/>
											)}
										/>
										{errors.service_locations && (
											<span className="text-danger">
												{errors.service_locations.message}
											</span>
										)}
									</div>
								</div>
							</div>
							 <div
								className="tab-pane fade"
								id="options"
								role="tabpanel"
								aria-labelledby="home-tab"
								>
								<div className="row mt-5">
									<div className="col-sm-5 panel">
										<h5>Service Add-ons</h5>
										<p>
											<small>Add-ons are unique options meant to enhance or customize
												a service. Groups are used to organize add-ons during
												booking and checkout.</small>
										</p>
										<button className="btn btn-primary purple">Create New Group</button>
									</div>
								</div>
								<div className="row mt-5 proUsage">
									<div className="col-sm-5 panel">
										<h5>Product Usage</h5>
										<p>
											<small>Add a product required by this service, and optionally adjust the price.</small>
										</p>
										<div className="form-group mt-5">
											<label htmlFor="" className="form-label label">
												Product Name<span>*</span>
											</label>
											<input
												type="text"
												className="form-control"
												name=""
												placeholder="Search by product name, SKU, or barcode"
											/>
										</div>
									</div>
								</div>
							</div> 
						</div>
					</div>
					<div className="panel-footer panel">
						<div className="row">
							<div className="col">

								<button
									className={(singleService.status==1)?'btn btn-danger': 'btn btn-success'}
									onClick={() => updateStatus(singleService.id, singleService.status)}
								>
									{(singleService.status==1)?'Deactivate': 'Activate'}
								</button>
								{/*<button className="btn btn-default ms-2">Duplicate</button>*/}
							</div>
							<div className="col text-end">
								<button
									className="btn btn-primary"
									onClick={handleSubmit(updateHandler)}
								>
									Save Changes
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditServiceModal;
