import React, { useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";
const EditAddonsModal = ({ singleAddon, ListAddon }) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors, isDirty, isValid },
	} = useForm({
		mode: "onTouched",
		defaultValues: singleAddon,
	});

	//console.log(singleAddon);

	useEffect(() => {
		reset(singleAddon);
	}, [singleAddon]);

	//console.log(errors);

	const onSubmit = (data) => {
		console.log(data);
		axios
			.post(`${API_BASE_URL}/update-addons`, data, HEADERS)
			.then((response) => {
				if (response.status === 200) {
					ListAddon();
					//reset();
					toast.success("update successfully!");
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<div
			className="modal modalWrap fade"
			id="addonEdits"
			tabIndex="-1"
			aria-labelledby="AddonsModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable panel-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="AddonsModalLabel">
							<strong>Add New Add-ons</strong>
						</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div className="modal-body modalBody row align-items-center">
						<div className="col-12">
							<form>
								<div className="row g-0 justify-content-center mt-4 mb-4">
									<div className="form-group col-6 pe-2">
										<label htmlFor="title" className="form-label label">
											Title <span>*</span>
										</label>
										<input
											type="text"
											className="form-control"
											id="title"
											{...register("service_title", {
												required: "please enter addons",
											})}
										/>
										{errors.service_title && (
											<span className="text-danger">
												{errors.service_title?.message}
											</span>
										)}
									</div>
									<div className="form-group col-6 pe-2">
										<label htmlFor="description" className="form-label label">Description <span>*</span></label>
										<input
											type="text"
											className="form-control"
											id="description"
											{...register("service_description", {
												required: "please enter description",
											})}
										/>
										{errors.service_description && (
											<span className="text-danger">
												{errors.service_description?.message}
											</span>
										)}
									</div>
								</div>
								<div className="row g-0 justify-content-center mt-4 mb-4">
									<div className="form-group col-6 pe-2">
										<label htmlFor="price" className="form-label label">
											price <span>*</span>
										</label>
										<input
											type="text"
											className="form-control"
											id="service_price"
											{...register("service_price", { required: "please enter price" })}
										/>
										{errors.service_price && (
											<span className="text-danger">
												{errors.service_price?.message}
											</span>
										)}
									</div>
									

									<div className="form-group col-6 pe-2">
										<label htmlFor="duration" className="form-label label">Duration <span>*</span></label>
										<input
											type="text"
											className="form-control"
											id="duration"
											{...register("duration", {
												required: "Please enter service duration",
											})}
											
										/>
										{errors.duration && (
											<span className="text-danger">
												{errors.duration?.message}
											</span>
										)}
										
									</div>
								</div>
							</form>
						</div>
					</div>
					<div className="modal-footer row g-0">
						<div className="col text-center">
							<button
								type="button"
								className="btn btn-primary btn-lg"
								onClick={handleSubmit(onSubmit)}
								//disabled={!isDirty || !isValid}
							>
								update
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditAddonsModal;
