import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { API_BASE_URL } from "../../Helper/ApiConstants";
import axios from "axios";
import { useForm } from "react-hook-form";

const Login = () => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm();

	const loginSubmit = (data) => {
		axios.post(`${API_BASE_URL}/login`, data).then(function (response) {
			console.log(response);

			if (response.status === 201) {
				toast.error("Bad credentials");
				reset();
			}

			if (response.status === 200) {
				toast.success("Login successfully");
				localStorage.setItem("user_token", response.data.token);
				localStorage.setItem(
					"current_user",
					JSON.stringify(response.data.user)
				);
				localStorage.setItem("current_user_location",JSON.stringify(response.data.defaultLocation));
				localStorage.setItem("userData", JSON.stringify(response.data.user));

				
				// reset();
				window.location.reload();
			}
		});
	};

	const token = localStorage.getItem("user_token");
	if (token) {
	
		return <Redirect to="/" />;

	}

	return (
		<React.Fragment>
			{/* <ToastContainer /> */}
			<div className="container">
				<div className="row align-items-center vh-100">
					<div className="col-6 mx-auto">
						<div className="form-center center">
							<div
								className="card text-dark bg-light mb-3"
								style={{ maxWidth: "18rem" }}
							>
								<form
									onSubmit={handleSubmit(loginSubmit)}
									className="containers"
									noValidate
								>
									<div className="card-header">Login</div>
									<div className="card-body">
										<div className="mb-3">
											<label className="form-label" htmlFor="email">email</label>
											<input
												type="email"
												id="email"
												className="form-control"
												placeholder="Email"
												{...register("email", {
													required: "email is required",
												})}
											/>
											{errors.email && (
												<span className="text-danger">
													{errors.email.message}
												</span>
											)}
										</div>
										<div className="mb-3">
											<label className="form-label" htmlFor="password">password</label>
											<input
												type="password"
												id="password"
												className="form-control"
												placeholder="password"
												{...register("password", {
													required: "password is required",
												})}
											/>
											{errors.password && (
												<span className="text-danger">
													{errors.password.message}
												</span>
											)}
										</div>

										<div className="mb-3">
											<button className="btn btn-primary" type="submit">
												Login
											</button>
										</div>
										<div className="mb-3">
											<p className="form-text">Don't have an account?<Link to="/signup" className="pl-3"> here </Link>
											</p>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Login;
