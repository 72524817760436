export const Capitalize = (str) => {
  if(str){

    return str.charAt(0).toUpperCase() + str.slice(1);

    
  }

  return '';
};

export const UpperCase = (str) => {
  if(str){
    return str.toUpperCase();

  }

  return '';
};

export const ToFixed = (number) => {
  return number.toFixed(2);
};


