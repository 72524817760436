import React, { useState, useEffect } from "react";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import { API_BASE_URL, HEADERS, UPLOAD_URL } from "../../Helper/ApiConstants";
//import { toast } from "react-toastify";
import axios from "axios";
import { useForm } from "react-hook-form";
import { withRouter, useParams } from "react-router-dom";
import Header from "../../components/Header/Header";

import TimeInput from 'material-ui-time-picker'

const Location = (props) => {
	const [location, setLocation] = useState([]);
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({ mode: "onTouched", defaultValues: location });

	//console.log(location);

	const [logourl, setLogourl] = useState("");

	const { store_slug } = useParams();

	const [selectedTime, setSelectedTime] = useState(new Date('2023-04-13 8:00:00'));
	const [selectedTime1, setSelectedTime1] = useState(new Date('2023-04-13 20:00:00'));



	const [opentime, setOpentime] = useState(new Date('2023-04-13 8:00:00'));
	const [closetime, setClosetime] = useState(new Date('2023-04-13 20:00:00'));
	//console.log(location);


	const twoDigitDateTime = (datetime) => {

		const dateObj = new Date(datetime);

		// extract the different components of the date and time
		const year = dateObj.getFullYear();
		const month = ('0'+( dateObj.getMonth() +1 )).substr(-2); // Note: month is 0-indexed, so we need to add 1
		const day = ('0'+dateObj.getDate()).substr(-2); //dateObj.getDate();
		const hours = ('0'+dateObj.getHours()).substr(-2); //dateObj.getHours();
		const minutes = ('0'+dateObj.getMinutes()).substr(-2); //dateObj.getMinutes();
		//const seconds = ('0'+dateObj.getMonth() + 1).substr(-2); //dateObj.getSeconds();

		return datetime = `${year}-${month}-${day} ${hours}:${minutes}`;

	}

	const handleTimeChange = (time) => {
		
		
		//console.log(new Date(time).toLocaleTimeString());
		//console.log(time.toISOString().slice(0,10));		

		setOpentime(twoDigitDateTime(time));


		setSelectedTime(time);
	};

	const handleTimeChange1 = (time) => {
		//console.log(new Date( time.toISOString().slice(0,10) ) );

		//console.log(new Date(time).toLocaleTimeString());
		setClosetime(twoDigitDateTime(time));

		setSelectedTime1(time);
	};


	const onImageChange = (e) => {
		const [file] = e.target.files;
			//setStoreLogo(URL.createObjectURL(file));
			setLogourl(URL.createObjectURL(file));
		};

	const handleSubmitLocation = (data) => {
		const formData = new FormData();
		formData.append("files", data.store_logo[0]);
		formData.append("store_title", data.store_title);
		formData.append("store_email", data.store_email);
		formData.append("store_contact_no", data.store_contact_no);
		formData.append("address1", data.address1);
		formData.append("store_city", data.store_city);
		formData.append("open_timing", twoDigitDateTime(opentime));
		formData.append("close_timing", twoDigitDateTime(closetime));
		formData.append("store_state", data.store_state);
		formData.append("address", data.address);
		formData.append("business_url", data.business_url);
		formData.append("cancellation_amount", data.cancellation_amt);
		formData.append("website", data.website);
		formData.append("zip_code", data.zip_code);
		formData.append("is_cancellation", data.is_cancellation_percent);
		formData.append("id", location.id); //name of key for the content type

		//data = {dd:'dddd', Ff:'dfs sdaf asdf', ttt:"dfasd asdf asdf "};
		axios.post(API_BASE_URL + "/update-store-location", formData, HEADERS).then((res) => {
			if (res.status === 200) {
				//toast.success("Update Category successfully");
				///props.ListCategories();
			}
		});


		// axios
		// 	.get('http://spotbookingapi.test/api/update-store-location')
		// 	.then((response) => {
		// 		if (response.status === 200) {
		// 			//toast.success("Update Data successfully");
		// 			//props.listCategories();
		// 			//setLogo('');
		// 			//reset();
		// 		}
		// 	});
	};

	//console.log(store_slug);

	const getStoreDetail = () => {
		axios
			.get(`${API_BASE_URL}/get-store-location/${store_slug}`, HEADERS)
			.then((response) => {
				if (response.status === 200) {
					setLocation(response.data.stores);

					if(response.data.stores['close_timing']){

						setSelectedTime1(new Date(response.data.stores['close_timing']));

					}

					console.log("=========",response.data.stores['close_timing']);
					reset(response.data.stores);
					setLogourl(`${UPLOAD_URL}/` + response.data.stores.store_logo);
				}
			});
	};

	useEffect(() => {
		getStoreDetail();
	}, [reset]);

	return (
		<>
			<Header />
			<div id="settings" className="mainWrapper">
				<div id="settingsBar" className="statusBar settingsBar container-fluid">
					<div className="leftSide pageTitle">
						<h4 className="barTitle">Manage Business</h4>
					</div>
				</div>
				<div id="setPanelWrap" className="setWrap panelWrap bodyWrap p-0 container-fluid">
					<div className="row m-0 g-0 justify-content-end">
						<div id="left" className="serMenu leftSide sideBarMenu p-0 col">
							<LeftSidebar />
						</div>
						<div id="right" className="rightSide col panel busiDetails setBodyDetails">
							<form encType="multipart/form-data" onSubmit={handleSubmit(handleSubmitLocation)} >
								<div className="panel busiInfo justify-content-between shadow row g-0 mb-4">
									<div className="col-lg-3 secLabel pe-5">
										<h5 className="text-secondary m-0">Business Info <small>{location.store_title} Details about your overall
												business and headquarters.</small>
										</h5>
									</div>

									<div className="col-lg-8 pt-2 secDetail">
										<div className="row g-0">
											<div className="col-lg-3 left">
												<div className="busiessLogo">
													<img id="store_logo1" src={logourl} alt={location.store_title} />
											
													<div className="col-12 mt-3 text-center">
														<input type="file" id="store_logo" {...register("store_logo")} onChange={onImageChange} />
															{/* <button
																type="button"
																className="btn btn-primary purple"
																//onClick={onButtonClick}
															>
																<i className="fas fa-upload"></i>Business Logo
															</button> */}
													</div>
												</div>
											</div>
											<div className="col-lg-7 right pt-3 ps-3 businessInfo">
												<div className="row g-0">
													<div className="form-group col-12 mb-5">
														<label htmlFor="store_title" className="form-label label">Business Name<span>*</span></label>
														<input type="text" id="store_title" className="form-control" {...register("store_title", {
																required: "Please enter store Title",
															})}	/>
															{errors.store_title && (
															<span className="text-danger">{errors.store_title?.message}</span>
														)}
													</div>
													<div className="form-group col-12 mb-5">
														<label htmlFor="business_url" className="form-label label">Business URL<span>*</span></label>
														<input type="text" id="business_url" className="form-control" {...register("business_url", {
																required: "Please enter store url",
																// pattern: {
																//   value:
																//     /^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/,
																//   message: "Please enter a valid url",
																// },
															})}
														/>
														{ errors.business_url && (
															<span className="text-danger">{errors.business_url?.message}</span>
														)}
													</div>
													<div className="form-group col-12 mb-5">
														<label htmlFor="" className="form-label label">Headquarters Address<span>*</span></label>
														<input type="text" className="form-control mb-5" {...register("address", {
																required: "Please enter address",
															})} />
														{errors.address && (
															<span className="text-danger">{errors.address?.message}</span>
														)}
														<input
															type="text"
															className="form-control mb-5"
															{...register("address1", {
																required: "Please enter address",
															})}
														/>
														{errors.address1 && (
															<span className="text-danger">
																{errors.address1?.message}
															</span>
														)}
														<div className="row g-0">
															<div className="form-group col mb-0 pe-3">
																<label
																	htmlFor="store_city"
																	className="form-label label"
																>
																	City<span>*</span>
																</label>
																<input
																	type="text"
																	id="store_city"
																	className="form-control"
																	{...register("store_city", {
																		required: "Please enter city",
																	})}
																/>
																{errors.store_city && (
																	<span className="text-danger">
																		{errors.store_city?.message}
																	</span>
																)}
															</div>

															<div className="form-group col mb-0 pe-3">
																<label htmlFor="store_state" className="form-label label">State/Province<span>*</span></label>
																<input type="text" id="store_state" className="form-control"
																		{...register("store_state", {
																			required: "Please enter state",
																		})}
																	/>
																{errors.store_state && (
																	<span className="text-danger">
																		{errors.store_state?.message}
																	</span>
																)}
															</div>
															<div className="form-group col mb-0">
																<label
																	htmlFor="zip_code"
																	className="form-label label"
																>
																	Postal Code<span>*</span>
																</label>
																<input type="text" id="zip_code" className="form-control" 
																	{...register("zip_code", {
																		required: "Please enter postalcode",
																		// pattern: {
																		//   value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
																		//   message:
																		//     "Please enter a valid postalcode",
																		// },
																	})}
																/>
																{errors.zip_code && (
																	<span className="text-danger">
																		{errors.zip_code?.message}
																	</span>
																)}
															</div>
														</div>
													</div>



													<div className="form-group col-12 mb-5">
														<label htmlFor="store_contact_no" className="form-label label">Phone<span>*</span></label>
														<input type="tel" id="store_contact_no" className="form-control" {...register("store_contact_no", {
																required: "Please enter contact detail",
																// pattern: {
																//   value: /[0-9]{3}-[0-9]{3}-[0-9]{4}/,
																//   message: "Please enter correct contact",
																// },
															})}/>
														{errors.store_contact_no && (
															<span className="text-danger">{errors.store_contact_no?.message}</span>
														)}
													</div>
													<div className="form-group col-12">
														<label htmlFor="" className="form-label label">Website<span>*</span></label>
														<input type="text" className="form-control" {...register("website", {required: "Please enter website"})} />
														{errors.website && (
															<span className="text-danger">
																{errors.website?.message}
															</span>
														)}
													</div>


													<div className="form-group col mb-0 pe-3">
															<label htmlFor="open_timing" className="form-label label">Store Open Timing<span>*</span></label>

															<TimeInput  mode='12h'  value={selectedTime} onChange={handleTimeChange} className="form-control" />

															{/*<input type="text" id="open_timing" className="form-control" {...register("open_timing", {
																	required: "Please enter store's open timing",
																})}/>
															{errors.open_timing && (
																<span className="text-danger">{errors.open_timing?.message}</span>
															)}*/}
														</div>

														<div className="form-group col mb-0 pe-3">
															<label htmlFor="close_timing" className="form-label label">Store Close Timing<span>*</span></label>
															<TimeInput value={selectedTime1} mode='12h'  onChange={handleTimeChange1} className="form-control"  />
															
															{/*<input type="text" id="close_timing" className="form-control" {...register("close_timing", {
																	required: "Please enter store's close timing",})} />

															{errors.close_timing && (
																<span className="text-danger">{errors.close_timing?.message}</span>
															)}*/}
														</div>

													<div className="row g-0">
														
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="panel busiContact justify-content-between shadow row g-0 mb-4">
									<div className="col-lg-3 secLabel">
										<h5 className="text-secondary m-0">
											Billing Contact
											<small>
												You can update your preferred contact for your Boulevard
												membership at any time.
											</small>
										</h5>
									</div>
									<div className="col-lg-8 pt-2 secDetail">
										<div className="row g-0">
											<div className="col-lg-4 left billingContact">
												<h6>Billing Contact<small>All billing-related emails will be sent to this email.</small></h6>
											</div>
											<div className="col-lg-6 right  ps-3 billingEmail">
												<div className="row g-0">
													<div className="form-group col-12 mb-4">
														<input
															type="email"
															className="form-control"
															{...register("store_email", {
																required: "Please enter store email",
																// pattern: {
																//   value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
																//   message: "Please enter valid email",
																// },
															})}
														/>
														{errors.store_email && (
															<span className="text-danger">{errors.store_email?.message}</span>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="panel cancellationFee justify-content-between shadow row g-0 mb-4">
									<div className="col-lg-3 secLabel">
										<h5 className="text-secondary m-0">
											Cancellation Fee
											<small>
												Customize a fee for any appointments cancelled after the
												cancellation period.
											</small>
										</h5>
									</div>
									<div className="col-lg-8 pt-2 secDetail">
										<div className="row g-0">
											<div className="col-lg-4 canFee pe-4">
												<h6>
													Cancellation Fee
													<small>
														Set a cancellation fee either by percentage or
														dollar amount.
													</small>
												</h6>
											</div>
											<div className="col-lg-6 right ps-3 setPercentage">
												<div className="row g-0">
													<div className="form-check radio col-12 mb-4">
														<label
															className="form-check-label"
															htmlFor="flexRadioDefault1"
														>
															<input
																type="radio"
																value="percentge"
																id="flexRadioDefault1"
																className="form-check-input"
																{...register("is_cancellation_percent", {
																	required: "Please select one option",
																})}
															/>
															<strong>Set as percentage</strong>
															<small>
																This fee applies to the cost of scheduled
																service(s) only.
															</small>
														</label>
													</div>
													<div className="form-group col-4 ms-5 mb-5">
														<label htmlFor="" className="form-label label">
															Cancellation fee<span>*</span>
														</label>
														<input
															type="text"
															className="form-control"
															{...register("cancellation_amt", {
																required:
																	"Please enter the cancellation amount",
															})}
														/>
														{errors.cancellation_amt && (
															<span className="text-danger">
																{errors.cancellation_amt?.message}
															</span>
														)}
													</div>
													<div className="form-check radio col-12 mb-4">
														<input
															type="radio"
															value="fixed"
															id="flexRadioDefault2"
															className="form-check-input"
															{...register("is_cancellation_percent", {
																required: "Please select one option",
															})}
														/>
														<label
															className="form-check-label"
															htmlFor="flexRadioDefault2"
														>
															<strong>Set as dollar amount</strong>
														</label>
														{errors.is_cancellation_percent && (
															<span className="text-danger">
																{errors.is_cancellation_percent?.message}
															</span>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<input type="submit" className="btn btn-success" />
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withRouter(Location);
