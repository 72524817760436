import React from "react";

import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
//import slugify from "react-slugify";
/* Pages */
import Dashboard from "./Pages/Dashboard/Dashboard";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import Error from "./Pages/Error/Error";
import Product from "./Pages/Product/Product";
import Supplier from "./Pages/Supplier/Supplier";
import ProductCategory from "./Pages/ProductCategory/ProductCategory";
import Category from "./Pages/Category/Category";
import Services from "./Pages/Services/Services";
import StaffRoles from "./Pages/StaffRoles/StaffRoles";
import Clients from "./components/Clients/Clients";
import Sales from "./Pages/Sales/Sales";
import Settings from "./Pages/Settings/Settings";
import StoreLocations from "./Pages/StoreLocations/StoreLocations";
import Location from "./Pages/StoreLocations/Location";
import Calendar from "./Pages/Calendar/Calendar";
import Employees from "./Pages/Employees/Employees";
import Addons from "./Pages/Add-ons/Add-ons";
import SelectLocation from "./Pages/SelectLocation/SelectLocation";
//import Appointment from "./Pages/Appointment/Appointment";
//import BookingLocation from "./Pages/Appointment/BookingLocation";
//import BookingService from "./Pages/Appointment/BookingService";
// import BookingPersonalFrom from "./Pages/Appointment/BookingPersonalFrom";
// import BookingPaymentForm from "./Pages/Appointment/BookingPaymentForm";
/* components */

/* Helper */
import ProtectedRoutes from "./Helper/ProtectedRoutes";

//let crrUser = JSON.parse(localStorage.getItem("current_user"));
//const brandLocation = crrUser.brand_name;
//console.log(brandLocation);
//console.log(locationTitle.store_title);
/* const locationTitleWithSlug = slugify(locationTitle.store_title, {
	delimiter: "_",
	prefix: "user-content",
}); */
/* const locationTitleWithSlug = slugify(locationTitle.store_title);
console.log(locationTitleWithSlug); */
/* console.log("dddddddddddddd");
console.log(locationTitleWithSlug); */
// -> "something-i-want-to-test"

// https://go.booker.com/brand/mellowbrand/locations
// https://go.booker.com/location/MellowMassageHollywood/service-menu
// https://go.booker.com/location/MellowMassageHollywood/detail-summary/4051019
// https://go.booker.com/location/MellowMassageHollywood/service/4051019/%20%20%20Restorative%20Massage:%2050%20minutes/select-staff

function App() {
	return (
		<>
			<Router>
				<div className="App">
					<Switch>
						{/***************** Public Routes *********************/}
						<Route exact path="/signin" component={Login} />
						<Route exact path="/signup" component={Register} />

						

						{/***************** Private Routes (Protected Routes) *********************/}
						<ProtectedRoutes
							exact
							path="/selectlocation"
							component={SelectLocation}
						/>
						
						<ProtectedRoutes exact path="/" component={Dashboard} />
						<ProtectedRoutes exact path="/suppliers" component={Supplier} />
						<ProtectedRoutes exact path="/calendar" component={Calendar} />
						<ProtectedRoutes exact path="/clients" component={Clients} />
						<ProtectedRoutes exact path="/sales" component={Sales} />
						<ProtectedRoutes exact path="/settings" component={Settings} />
						<ProtectedRoutes exact path="/services" component={Services} />
						<ProtectedRoutes exact path="/add-ons" component={Addons} />
						<ProtectedRoutes exact path="/store-locations" component={StoreLocations} />
						<ProtectedRoutes exact path="/location/:store_slug" component={Location} />
						<ProtectedRoutes exact path="/staff-roles" component={StaffRoles} />
						<ProtectedRoutes exact path="/categories" component={Category} />
						<ProtectedRoutes exact path="/products" component={Product} />
						<ProtectedRoutes exact path="/products-categories" component={ProductCategory} />
						<ProtectedRoutes
							exact
							path="/all-employees"
							component={Employees}
						/>
						<Route exact path="/*" component={Error} />
					</Switch>
				</div>
			</Router>
			<ToastContainer />
		</>
	);
}

export default App;
