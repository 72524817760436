import React from "react";
import { UPLOAD_URL } from "../../Helper/ApiConstants";

const EmployeesList = ({ employee, empData }) => {
	return (
		<div className="middlePart mt-3 col-12">
			<div className="row g-0 mt-2">
				<div className="col panel serblock p-0">
					<table className="panelTable shadow shadow table table-hover">
						<thead>
							<tr>
								<th className="firstName">
									First Name <i className="far fa-arrow-up"></i>
								</th>
								<th className="lastName">
									Last Name <i className="far fa-arrow-up"></i>
								</th>
								<th className="mobile">
									Mobile <i className="far fa-arrow-up"></i>
								</th>
								<th className="email">
									Email <i className="far fa-arrow-up"></i>
								</th>
								<th className="invite">
									Invite <i className="far fa-arrow-up"></i>
								</th>
								<th className="staffRole">
									Staff Role <i className="far fa-arrow-up"></i>
								</th>
								<th className="privilegeGroup col-2">
									Status
									<i className="far fa-arrow-up"></i>
								</th>
							</tr>
						</thead>
						<tbody>
							{employee.map((emp, i) => (
								<tr
									data-bs-toggle="modal"
									data-bs-target="#employeeEdits"
									key={i}
									onClick={() => empData(emp)}
								>
									<td className="firstName">
										<span className="avatar">
											{emp.profile_img == null && (
												<i className="fas fa-user-circle"></i>
											)}

											{emp.profile_img != null && (
												<img
													className="profile-img"
													src={`${UPLOAD_URL}/${emp.profile_img}`}
													alt={emp.first_name}
												/>
											)}
										</span>
										{emp.first_name}
									</td>
									<td className="lastName">{emp.last_name}</td>
									<td className="mobile">{emp.contact_number || "-"}</td>
									<td className="email">{emp.email}</td>
									<td className="invite">Confirmed</td>
									<td className="staffRole">{emp.staffrole}</td>
									<td className="privilegeGroup col-2">
										{emp.status == true ? "Active" : "Deactive"}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default EmployeesList;
