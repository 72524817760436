import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";
import { withRouter } from "react-router-dom";
//import { ToastContainer } from "react-toastify";
import Header from "../../components/Header/Header";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import EmployeesAddModal from "../../components/Employees/EmployeesAddModal";
import EmployeesEditModal from "../../components/Employees/EmployeesEditModal";
import EmployeesList from "../../components/Employees/EmployeesList";

const Employees = () => {
	
	/* all employee */
	const [employee, setEmployee] = useState([]);
	
	/* all staff members */
	const [staffrole, setStaffrole] = useState([]);
	
	/* all locations */
	const [location, setLocation] = useState([]);
	/* all services */
	const [services, setServices] = useState([]);
	
	/* single user */
	const [userData, setUserData] = useState("");

	//Get product list
	const getEmployeeList = () => {
		axios
			.get(`${API_BASE_URL}/employee-list`, HEADERS)
			.then(function (response) {
				if (response.status === 200) {
					setEmployee(response.data);
				}
			});
	};

	const empData = (data) => {
		const locations = [];
		const dd = data.staff_locations;

		if (dd) {
			for (let val of dd) {
				locations.push(val.location_id);
			}
		}

		const newData = { ...data, staff_locations: locations };

		setUserData(newData);
	};

	const getStaffList = () => {
		axios
			.get(`${API_BASE_URL}/staff-list-dropdown`, HEADERS)
			.then((response) => {
				setStaffrole(response.data.staff);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getLocationList = () => {
		axios
			.get(`${API_BASE_URL}/stores-locations-dropdown`, HEADERS)
			.then((response) => {
				setLocation(response.data.stores);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getServices = () => {
		axios
			.get(`${API_BASE_URL}/list-services-dropdown`, HEADERS)
			.then((response) => {
				setServices(response.data.services);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	

	useEffect(() => {
		getEmployeeList();
		getLocationList();
		getServices();
		getStaffList();
	}, []);

	return (
		<React.Fragment>
			<Header />
			{/* <ToastContainer /> */}
			{/* <!-- Wrapper --> */}
			<div id="settings" className="mainWrapper">
				{/* <!-- Status Bar --> */}
				<div id="settingsBar" className="statusBar settingsBar container-fluid">
					<div className="leftSide pageTitle">
						<h4 className="barTitle">Manage Business</h4>
					</div>
					<div className="rightSide AddNewDiscount">
						<button
							className="btn btn-primary purple"
							data-bs-toggle="modal"
							data-bs-target="#addNewEmployee"
						>
							New Employee
						</button>
					</div>
				</div>
				{/* <!--End of Status Bar --> */}
				<div
					id="setPanelWrap"
					className="setWrap panelWrap bodyWrap p-0 container-fluid"
				>
					<div className="row m-0 g-0 justify-content-end">
						<div id="left" className="serMenu leftSide sideBarMenu p-0 col">
							<LeftSidebar />
						</div>
						<div
							id="right"
							className="rightSide col panel busiDetails setBodyDetails"
						>
							<div className="row g-0">
								<div className="topPart col-12">
									<div className="row align-items-center">
										<div className="panelHead col">
											<h3 className="mb-0">
												<i className="fas fa-circle success"></i> Active Employees
											</h3>
										</div>
										<div className="col-3 right filterSearch staffInvite text-end">
											<button className="btn btn-simple p-0">Active <i className="fas fa-chevron-down"></i></button>
											<button className="btn btn-simple p-0 ms-3 me-3"><i className="far fa-search"></i></button>
											<button className="btn btn-default">Invite Staff</button>
										</div>
									</div>
								</div>
								<EmployeesList employee={employee} empData={empData} />
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <EmployeesAddModal /> */}
			<EmployeesAddModal
				getEmployeeList={getEmployeeList}
				staffrole={staffrole}
				location={location}
			/>
			{/* <EmployeesEditModal /> */}
			<EmployeesEditModal
				userData={userData}
				location={location}
				services={services}
				getEmployeeList={getEmployeeList}
				staffrole={staffrole}
				
			/>
			{/* <!-- End-Wrapper --> */}
		</React.Fragment>
	);
};

export default withRouter(Employees);
