import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";
import Select from "react-select";

const AddServiceModal = ({
	categoriesList,
	storeLocations,
	ListServices,
	allService,
	addonsList,
}) => {
	const {
		register,
		control,
		handleSubmit,
		reset,
		//formState: { errors, isDirty, isValid },
		formState: { errors },
	} = useForm({
		//mode: "onTouched",
		mode: "onChange",
	});

	const onSubmit = (data) => {
		//console.log(data);
		axios
			.post(`${API_BASE_URL}/add-service`, data, HEADERS)
			.then((response) => {
				if (response.status === 200) {
					toast.success("service add successfully");
					reset();
					ListServices();
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<div
			className="modal modalWrap fade"
			id="addNewService"
			tabIndex="-1"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLabel">
							<strong>New Service</strong>
						</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div className="modal-body modalBody row align-items-center">
						<div className="col-12">
							<form
								className="row g-0 justify-content-center"
								//onSubmit={handleSubmit(onSubmit)}
							>
								<div className="form-group col-12 mb-5">
									<label htmlFor="" className="form-label label">
										Services Name
									</label>
									<input
										type="text"
										className="form-control"
										placeholder="Ex. Hair Cut"
										{...register("service_name", {
											required: "service name is required",
										})}
									/>
									{errors.service_name && (
										<span className="text-danger">
											{errors.service_name.message}
										</span>
									)}
								</div>

								<div className="form-group col-12 mb-5">
									<label htmlFor="" className="form-label label">Services Description</label>
									<input
										type="text"
										className="form-control"
										placeholder="Description"
										{...register("service_description", {
											required: "description is required",
										})}
									/>
									{errors.service_description && (
										<span className="text-danger">
											{errors.service_description.message}
										</span>
									)}
								</div>

								<div className="form-group col-12 mb-5">
									<label htmlFor="" className="form-label label">
										Services Price
									</label>
									<input
										type="text"
										className="form-control"
										placeholder="Services Price"
										{...register("service_price", {
											required: "services price is required",
										})}
									/>
									{errors.service_price && (
										<span className="text-danger">
											{errors.service_price.message}
										</span>
									)}
								</div>

								{/*<div className="form-group col-12 mb-5">
									<label htmlFor="" className="form-label label">
										Discount Price
									</label>
									<input
										type="text"
										className="form-control"
										placeholder="Discount Price"
										{...register("service_discount_price", {
											required: "discount price is required",
										})}
									/>
									{errors.service_discount_price && (
										<span className="text-danger">
											{errors.service_discount_price.message}
										</span>
									)}
								</div>*/}

								<div className="form-group col-12">
									<label htmlFor="service_category" className="form-label label">Select A Category...</label>

									<Controller
										name="service_category"
										control={control}
										render={({ field: { onChange, value, ref } }) => (
											<Select
												inputRef={ref}
												isSearchable={true}
												options={allService}
												//value={staffrole.find((c) => c.value === value)}
												onChange={(val) => onChange(val.value)}
											/>
										)}
										rules={{ required: "This is required" }}
									/>
									{errors.service_category && (
										<span className="text-danger">{errors.service_category.message}</span>
									)}
								</div>

								<div className="form-group locations col-12">
									<h5 className="bigLabel">Wants to provide addons to this service?</h5>
									<Controller
										name="addons"
										control={control}
										render={({ field: { onChange, value, ref } }) => (
											<Select
												inputRef={ref}
												isSearchable={true}
												onChange={(val) => onChange(val.map((c) => c.value))}
												options={addonsList}
												isMulti
											/>
										)}
										//rules={{ required: "This is required" }}
									/>
									{errors.addons && (
										<span className="text-danger">{errors.addons.message}</span>
									)}
								</div>
								<div className="form-group locations col-12">
									<h5 className="bigLabel">Which locations provide this service?</h5>
									<Controller
										name="store_location"
										control={control}
										//defaultValue={categoriesList.map((c) => c.category_name)}
										render={({ field: { onChange, value, ref } }) => (
											<Select
												inputRef={ref}
												isSearchable={true}
												//value={options.filter((c) => value.includes(c.value))}
												onChange={(val) => onChange(val.map((c) => c.value))}
												options={storeLocations}
												isMulti
											/>
										)}
										rules={{ required: "This is required" }}
									/>
									{errors.store_location && (
										<span className="text-danger">
											{errors.store_location.message}
										</span>
									)}
									{/* <input
												type="search"
												className="form-control"
												placeholder="Searh Locations"
											/> */}

									{/* {storeLocations.map((storeLocation, index) => (
										<div key={index} className="form-check checkbox mt-4">
											<input
												className="form-check-input"
												type="checkbox"
												value={storeLocation.id}
												id={"location_" + index}
												{...register("store_location")}
											/>
											<label
												className="form-check-label"
												htmlFor={"location_" + index}
											>
												{Capitalize(storeLocation.store_title)}{" "}
												<strong>( {storeLocation.store_city} )</strong>
											</label>
										</div>
									))} */}

									{/* <div className="form-check checkbox">
												<input
													className="form-check-input"
													type="checkbox"
													value=""
													id="Brooklyn"
												/>
												<label className="form-check-label" htmlFor="Brooklyn">
													Fox and Jane Salon <strong>(Brooklyn)</strong>
												</label>
											</div>
											<div className="form-check checkbox">
												<input
													className="form-check-input"
													type="checkbox"
													value=""
													id="Colorado"
												/>
												<label className="form-check-label" htmlFor="Colorado">
													Fox and Jane Salon <strong>(Colorado Springs)</strong>
												</label>
											</div>
											<div className="form-check checkbox">
												<input
													className="form-check-input"
													type="checkbox"
													value=""
													id="Denver"
												/>
												<label className="form-check-label" htmlFor="Denver">
													Fox and Jane Salon <strong>(Denver)</strong>
												</label>
											</div>
											<div className="form-check checkbox">
												<input
													className="form-check-input"
													type="checkbox"
													value=""
													id="Easy"
												/>
												<label className="form-check-label" htmlFor="Easy">
													Fox and Jane Salon <strong>(Easy Village)</strong>
												</label>
											</div>
											<div className="form-check checkbox">
												<input
													className="form-check-input"
													type="checkbox"
													value=""
													id="Little"
												/>
												<label className="form-check-label" htmlFor="Little">
													Fox and Jane Salon <strong>(Little Lion)</strong>
												</label>
											</div>
											<div className="form-check checkbox">
												<input
													className="form-check-input"
													type="checkbox"
													value=""
													id="Lower"
												/>
												<label className="form-check-label" htmlFor="Lower">
													Fox and Jane Salon <strong>(Lower Easy Side)</strong>
												</label>
											</div>
											<div className="form-check checkbox">
												<input
													className="form-check-input"
													type="checkbox"
													value=""
													id="San"
												/>
												<label className="form-check-label" htmlFor="San">
													Fox and Jane Salon <strong>(San Diego)</strong>
												</label>
											</div>
											<div className="form-check checkbox">
												<input
													className="form-check-input"
													type="checkbox"
													value=""
													id="Somerville"
												/>
												<label
													className="form-check-label"
													htmlFor="Somerville"
												>
													Fox and Jane Salon <strong>(Somerville)</strong>
												</label>
											</div>
											<div className="form-check checkbox">
												<input
													className="form-check-input"
													type="checkbox"
													value=""
													id="Toronto"
												/>
												<label className="form-check-label" htmlFor="Toronto">
													Fox and Jane Salon <strong>(Toronto)</strong>
												</label>
											</div> */}
								</div>
							</form>
						</div>
					</div>
					<div className="modal-footer row">
						<div className="col text-center">
							<button
								type="button"
								className="btn btn-primary btn-lg"
								onClick={ handleSubmit(onSubmit) }
								//disabled={!isDirty || !isValid}
							>
								Add Service
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddServiceModal;
