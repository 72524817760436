import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../../Helper/ApiConstants";

const DeleteCategoryModal = (props) => {
	const handleDelete = (cat_id) => {
		const data = { cat_id: cat_id };

		axios.post(API_BASE_URL + "/delete-category", data, HEADERS).then((res) => {
			if (res.status === 200) {
				toast.error("Employee delete successfully");
				props.ListCategories();
			} else {
			}
		});
	};

	return (
		<div
			className="modal modalWrap fade"
			id={"deleteCatModal"}
			tabIndex="-1"
			aria-labelledby="deleteCatModal"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Are you Sure?</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div className="modal-body">
						<p>Are you Sure? want to delete Record.</p>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-secondary"
							data-bs-dismiss="modal"
						>
							Cancel
						</button>
						<button
							type="button"
							className="btn btn-primary"
							data-bs-dismiss="modal"
							onClick={() => handleDelete(props.modalId)}
						>
							Delete
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DeleteCategoryModal;
