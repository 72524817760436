import React, { useEffect, useState } from "react";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Header from "../../components/Header/Header";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
//import { ToastContainer } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";
import ProductCategoriesList from "../../components/ProductCategory/ProductCategoriesList/ProductCategoriesList";
import AddProCateModal from "../../components/ProductCategory/ProductCategoriesModal/AddProCateModal";
import UpdateProCateModal from "../../components/ProductCategory/ProductCategoriesModal/UpdateProCateModal";
import DeleteProCateModal from "../../components/ProductCategory/ProductCategoriesModal/DeleteProCateModal";

const ProductCategory = () => {
  const [pCategories, setpCategories] = useState([]);
  const [productCat, setProductCat] = useState("");

  //Get product-category list
  const getProductCategoryList = () => {
    axios
      .get(`${API_BASE_URL}/product-category-list`, HEADERS)
      .then(function (response) {
        if (response.status === 200) {
          setpCategories(response.data);
        }
      });
  };

  const openModal = (data) => {
    setProductCat(data);
  };

  useEffect(() => {
    getProductCategoryList();
  }, []);

  return (
    <>
      <Header />
      <div id="settings" className="mainWrapper">
        {/* <ToastContainer /> */}
        {/* <!-- Status Bar --> */}
        <div id="settingsBar" className="statusBar settingsBar container-fluid">
          <div className="leftSide pageTitle">
            <h4 className="barTitle">Settings</h4>
          </div>
          <div className="rightSide AddNew">
            <button
              className="btn btn-primary purple"
              data-bs-toggle="modal"
              data-bs-target="#ModalCreate"
              //data-bs-whatever="@mdo"
            >
              Create Categories
            </button>
          </div>
        </div>
        {/*  <!--End of Status Bar --> */}
        <div
          id="setPanelWrap"
          className="setWrap panelWrap bodyWrap p-0 container-fluid"
        >
          <div className="row m-0 g-0 justify-content-end">
            <div id="left" className="serMenu leftSide sideBarMenu p-0 col">
              <LeftSidebar />
            </div>
            <div
              id="right"
              className="rightSide col panel serDetails setBodyDetails"
            >
              <div className="row g-0">
                <div className="topPart col-12">
                  {/* <!-- End of Wrapper--> */}
                  <div className="row">
                    <div className="panelHead col mb-2">
                      <h3 className="mb-0">Product Categories</h3>
                    </div>
                    <div className="col staDroMenu text-end">
                      <button className="btn btn-simple p-0">
                        Active <i className="fas fa-chevron-down"></i>
                      </button>
                    </div>
                  </div>
                  {/* product category list */}
                  <ProductCategoriesList
                    pCategories={pCategories}
                    openModal={openModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* add modal */}
      <AddProCateModal getProductCategoryList={getProductCategoryList} />
      {/* update modal */}
      <UpdateProCateModal
        productCat={productCat}
        getProductCategoryList={getProductCategoryList}
      />
      {/* delete modal */}
      <DeleteProCateModal
        modalId={productCat.id}
        getProductCategoryList={getProductCategoryList}
      />
    </>
  );
};

export default withRouter(ProductCategory);
