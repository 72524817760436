import React from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../../Helper/ApiConstants";
import axios from "axios";
import Select from "react-select";

const AddProductModal = ({ getProductList, supplier }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    //mode: "onTouched",
  });

  //submit product form data
  const onSubmit = (data) => {
    axios
      .post(`${API_BASE_URL}/add-product`, data, HEADERS)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Product create successfully");
          getProductList();
          reset();
        }
      });
  };

  return (
    <div
      className="modal modalWrap fade "
      id="CreateProductModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable panel-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              <strong>New Product</strong>
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body modalBody row align-items-center">
            <div className="col-12">
              <form>
                <div className="row g-0 justify-content-center mt-4 mb-4">
                  <div className="form-group col-6 pe-2">
                    <label htmlFor="product_title" className="form-label label">
                      Product Title<span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="product_title"
                      {...register("product_title", {
                        required: "Product title required",
                      })}
                    />
                    {errors.product_title && (
                      <span className="text-danger">
                        {errors.product_title?.message}
                      </span>
                    )}
                  </div>

                  <div className="form-group col-6">
                    <label htmlFor="product_size" className="form-label label">
                      Size
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="product_size"
                      {...register("product_size")}
                    />
                  </div>
                </div>
                <div className="row g-0 justify-content-center mt-4 mb-4">
                  <div className="form-group col-6">
                    <label htmlFor="product_color" className="form-label label">
                      color
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="product_color"
                      {...register("product_color")}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label
                      htmlFor="product_barcode"
                      className="form-label label"
                    >
                      barcode
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="product_barcode"
                      {...register("product_barcode")}
                    />
                  </div>
                </div>

                <div className="row g-0 justify-content-center mt-4 mb-4">
                  <div className="form-group col-6">
                    <label
                      htmlFor="product_description"
                      className="form-label label"
                    >
                      description
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="product_description"
                      {...register("product_description")}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label htmlFor="product_price" className="form-label label">
                      price <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="product_price"
                      {...register("product_price", { required: true })}
                    />
                    {errors.product_price && (
                      <span className="text-danger">
                        Product price required
                      </span>
                    )}
                  </div>
                </div>

                <div className="row g-0 justify-content-center mt-4 mb-4">
                  <div className="form-group col-6">
                    <label
                      htmlFor="product_unit_cost"
                      className="form-label label"
                    >
                      Unit cost <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="product_unit_cost"
                      {...register("product_unit_cost", { required: true })}
                    />
                    {errors.product_unit_cost && (
                      <span className="text-danger">
                        Product unit cost required
                      </span>
                    )}
                  </div>
                  <div className="form-group col-6">
                    <label htmlFor="product_brand" className="form-label label">
                      brand<span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="product_brand"
                      {...register("product_brand", { required: true })}
                    />
                    {errors.product_brand && (
                      <span className="text-danger">
                        Product brand required
                      </span>
                    )}
                  </div>
                </div>

                <div className="row g-0 justify-content-center mt-4 mb-4">
                  <div className="form-group col-6">
                    <label htmlFor="product_sku" className="form-label label">
                      Sku
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="product_sku"
                      {...register("product_sku")}
                    />
                  </div>
                  <div className="form-group col-6">
                    <label htmlFor="supliers_id" className="form-label label">
                      Select supplier...
                    </label>
                    <Controller
                      id="supliers_id"
                      name="supliers_id"
                      control={control}
                      render={({ field: { onChange, value, ref } }) => (
                        <Select
                          options={supplier}
                          onChange={(val) => onChange(val.value)}
                        />
                      )}
                      rules={{ required: "This is required" }}
                    />
                    {errors.supliers_id && (
                      <span className="text-danger">
                        {errors.supliers_id.message}
                      </span>
                    )}
                    {/* <select
                      className="mdb-select md-form form-select"
                      id="supliers_id"
                      {...register("supliers_id")}
                    >
                      <option value="default">Option...</option>
                      {supplier.map((option) => (
                        <option value={option.value} key={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select> */}
                  </div>
                </div>

                {/* <div className="row g-0 justify-content-center mb-4">
										<div className="form-check checkbox">
											<input
												className="form-check-input"
												type="checkbox"
												defaultChecked="checked"
												value="1"
												id="is_subscription"
												{...register("is_subscription", { required: false })}
											/>
											<label
												className="form-check-label"
												htmlFor="is_subscription"
											>
												this is subscription.
											</label>
										</div>
									</div>
									<div className="row g-0 justify-content-center mb-4">
										<div className="form-check checkbox">
											<input
												className="form-check-input"
												type="checkbox"
												defaultChecked="checked"
												value="1"
												id="is_voucher_package"
												{...register("is_voucher_package", { required: false })}
											/>
											<label
												className="form-check-label"
												htmlFor="is_voucher_package"
											>
												this is voucher package.
											</label>
										</div>
									</div> */}
                <div className="row g-0 justify-content-center mb-4">
                  <div className="form-check checkbox">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="charge_tax"
                      {...register("charge_tax")}
                    />
                    <label className="form-check-label" htmlFor="charge_tax">
                      charge tax on this item.
                    </label>
                  </div>
                </div>
                <div className="row g-0 justify-content-center mb-4">
                  <div className="form-check checkbox">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="is_for_service"
                      {...register("is_for_service")}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="is_for_service"
                    >
                      this product can be used when performing a service.
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer row g-0">
            <div className="col text-center">
              <button
                type="submit"
                className="btn btn-primary btn-lg"
                onClick={handleSubmit(onSubmit)}
                disabled={!isDirty || !isValid}
              >
                Create Product
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductModal;
