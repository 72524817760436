import LeftStatusBar from "./LeftStatusBar";
import RightStatusBar from "./RightStatusBar";

const StatusBar = () => {
  return (
    <div id="statusBar" className="statusBar container-fluid">
      <LeftStatusBar />
      <RightStatusBar />
    </div>
  );
};

export default StatusBar;
