const LeftStatusBar = () => {
	return (
		<div className="leftSide datClientFilt">
			<div className="currentDate" div="true">
				<form className="dddd">
					<input id="datepicker" />
				</form>
			</div>
			{/*<form className="cliSearch">
				<input
					type="search"
					className="form-control"
					placeholder="Clients Filter"
				/>
			</form>*/}
		</div>
	);
};

export default LeftStatusBar;
