export const navData = [
	{
		id: 1,
		data: "Front Desk",
		to: "/",
		className: "nav-item",
	},
	{
		id: 2,
		data: "Calendar",
		to: "/calendar",
		className: "nav-item",
	},
	{
		id: 3,
		data: "Clients",
		to: "/clients",
		className: "nav-item",
	},
	{
		id: 4,
		data: "Sales",
		to: "/sales",
		className: "nav-item",
	},
	{
		id: 5,
		data: "Settings",
		to: "/settings",
		className: "nav-item",
	},
];
/***
 *
 * left side sidebar data
 *
 */
export const sideBarData = [
	{
		id: 1,
		data: "Apps & Integrations",
		to: "/apps-&-integrations",
		className: "menuItem",
	},
	{
		id: 2,
		data: "Billing",
		to: "/billing",
		className: "menuItem",
	},
	 
	{
		id: 6,
		data: "Employees",
		to: "/all-employees",
		className: "menuItem",
	},
	// {
	//   id: 7,
	//   data: "Forms",
	//   to: "/forms",
	//   className: "menuItem",
	// },
	{
		id: 8,
		data: "Locations",
		to: "/store-locations",
		className: "menuItem",
	},

	{
		id: 11,
		data: "Online Booking",
		to: "/online-booking",
		className: "menuItem",
	},
	{
		id: 12,
		data: "Payment Processing",
		to: "/payment-processing",
		className: "menuItem",
	},
	
	
	{
		id: 15,
		data: "Products Categories",
		to: "/products-categories",
		className: "menuItem",
	},
	{
		id: 16,
		data: "Products",
		to: "/products",
		className: "menuItem",
	},
	{
		id: 17,
		data: "Categories",
		to: "/categories",
		className: "menuItem",
	},
	{
		id: 18,
		data: "Services",
		to: "/services",
		className: "menuItem",
	},
	{
		id: 19,
		data: "Add-ons",
		to: "/add-ons",
		className: "menuItem",
	},
	{
		id: 20,
		data: "Staff Roles",
		to: "/staff-roles",
		className: "menuItem",
	},
	{
		id: 21,
		data: "Suppliers",
		to: "/suppliers",
		className: "menuItem",
	}
	
];
