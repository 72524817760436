import React from "react";

const PageNotFound = () => {
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <h1 className="display-1 fw-bold text-black">404</h1>
    </div>
  );
};

export default PageNotFound;
