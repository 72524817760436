import React from "react";
import { NavLink } from "react-router-dom";
import { sideBarData } from "../../Helper/Navigations";
const LeftSidebar = () => {
  return (
    <ul className="sideMenu">
      {sideBarData.map((sidebar, index) => (
        <li key={index} className={sidebar.className}>
          <NavLink to={sidebar.to}>{sidebar.data}</NavLink>
        </li>
      ))}
    </ul>
  );
};

export default LeftSidebar;
