import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";

import axios from "axios";
//import { Redirect } from "react-router-dom";
//import { StoreLocation } from "../../Helper/ApiCalls";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

const SelectLocation = () => {
	const [storeLocations, setStoreLocations] = useState([]);

	const current_user_location = JSON.parse(localStorage.getItem("current_user_location"));
	const [singleUserData, setSingleUserData] = useState('');

	const history = useHistory();

	//const loc_id = current_user_location.id;

	const {
			//register,
			control,
			handleSubmit,
			//reset,
			formState: { errors },
		} = useForm({
			defaultValues: {
				loc_id: singleUserData,
			},
		});

	const StoreLocation = async () => {
		try {
			const response = await axios.get(
				`${API_BASE_URL}/stores-locations-dropdown`,
				HEADERS
			);

			console.log(response.data.stores.length);
			if(response.data.stores.length == 0){

			}
			
			
			setStoreLocations(response.data.stores);
		} catch (error) {
			console.log(error);
		}
	};

	//const getUserDefaltLoaction = localStorage.getItem("user_token");

	/* const userData = () => {
		axios.get(`${API_BASE_URL}/get-user`, HEADERS).then(function (response) {
			console.log(response.data);

			if (response.status === 200) {
				console.log("sucessfully");
				//setSingleUserData(response.data.defaultLocation[0].id);
				//  localStorage.setItem(
				//   "current_user",
				//   JSON.stringify(response.data.user)
				// );
				// localStorage.setItem(
				//   "current_user_location",
				//   JSON.stringify(response.data.defaultLocation)
				// );
			}
		});
	}; */

	//console.log(singleUserData);
	//console.log(storeLocations);
	//console.log(loc);

	const loginSubmit = (data) => {
		console.log(data);

		axios
			.post(`${API_BASE_URL}/get-updated-location`, data, HEADERS)
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					localStorage.removeItem("current_user_location");
					localStorage.setItem(
						"current_user_location",
						JSON.stringify(response.data.SelectedLocation)
					);
					history.push("/");
					//toast.success("Service Update successfully");
					//ListServices();
				}
			});
	};

	useEffect(() => {
		StoreLocation();
	}, []);

	useEffect(() => {
		//reset(loc_id);
	}, [singleUserData]);

	return (
		<>
			<div className="container">
				<div className="row align-items-center vh-100">
					<div className="col-6 mx-auto">
						<div className="form-center center sleLoc">
							<div className="card text-dark bg-light mb-3">
								<form onSubmit={handleSubmit(loginSubmit)}>
									<div className="panel advLocaSetting mt-5">
										<div className="row g-0">
											<h5 className="bigLabel lcenter">Select Location</h5>

											<Controller
												name="loc_id"
												//defaultValue={singleUserData ? singleUserData : ""}
												control={control}
												className="mdb-select md-form form-select"
												render={({ field: { onChange, value, ref } }) => (
													<Select
														inputRef={ref}
														isSearchable={true}
														options={storeLocations}
														value={storeLocations.find(
															(c) => c.value === value
														)}
														onChange={(val) => onChange(val.value)}
													/>
												)}
												rules={{ required: "This is required" }}
											/>
											{/* {errors.service_locations && (
												<span className="text-danger">
													{errors.service_locations.message}
												</span>
											)} */}
										</div>
										<div>
											<button type="submit">submit</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withRouter(SelectLocation);
