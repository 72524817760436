import React, { useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../../Helper/ApiConstants";

const SupplierEditModal = ({ supplier, getSupplierList }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: supplier,
  });

  useEffect(() => {
    reset(supplier);
  }, [supplier]);

  function updateHandler(data) {
    axios
      .post(`${API_BASE_URL}/update-supplier`, data, HEADERS)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Supplier Update successfully");

          getSupplierList();

          /* setTimeout(() => {
					//location.reload();
				}, 2500); */
        }
      });
  }
  return (
    <div
      className="modal modalWrap slidePanel fade"
      id={`editSupplier`}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="panel-dialog modal-dialog small-dialog modal-dialog-scrollable panel-lg">
        <div className="panel-content modal-content">
          <div className="close-panel row g-o text-align-center align-middle">
            <div className="col">
              <h5 className="panel-title mb-0 p-0 text-white">
                <div
                  className="fal fa-times"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></div>
                <span className="ms-2">Edit Supplier</span>
              </h5>
            </div>
          </div>
          <div className="modal-body panelBody">
            <form action="" className="row g-0">
              <div className="form-group col-12 mt-5 mb-5">
                <label htmlFor="" className="form-label label">
                  Supplier Name<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="supplier_name"
                  defaultValue={supplier.supplier_name}
                  {...register("supplier_name", {
                    required: "Name is Required",
                  })}
                />
                {errors.supplier_name && (
                  <span className="text-danger">
                    {errors.supplier_name.message}
                  </span>
                )}
              </div>
              <div className="form-group col-12 mb-5">
                <label htmlFor="" className="form-label label">
                  Email<span>*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="supplier_email"
                  {...register("supplier_email", {
                    required: "Please Enter Email",
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Please enter valid email",
                    },
                  })}
                />
                {errors.supplier_email?.message && (
                  <span className="text-danger">
                    {errors.supplier_email?.message}
                  </span>
                )}
              </div>
              <div className="form-group col-12 mb-5">
                <label htmlFor="" className="form-label label">
                  Phone Number<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="supplier_contact"
                  {...register("supplier_contact", {
                    required: "Please Enter Contact no.",
                    pattern: {
                      // value: /^[0-9]*$/,
                      value: /[0-9]{3}-[0-9]{3}-[0-9]{4}/,
                      message: "Please Enter Numbers Only",
                    },
                  })}
                />
                {errors.supplier_contact?.message && (
                  <span className="text-danger">
                    {errors.supplier_contact?.message}
                  </span>
                )}
              </div>
              <div className="form-group col-12">
                <label htmlFor="" className="form-label label">
                  Address<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  {...register("address", {
                    required: "Please Enter Address.",
                  })}
                />
                {errors.address?.message && (
                  <span className="text-danger">{errors.address?.message}</span>
                )}
              </div>
              <div className="form-group col-12">
                <label htmlFor="" className="form-label label">
                  Apt, Suite, etc<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="supplier_fax_no"
                  {...register("supplier_fax_no")}
                />
              </div>
              <div className="detailAddress col-12 mb-5">
                <div className="row g-0">
                  <div className="form-group col-6 pe-3">
                    <label htmlFor="" className="form-label label">
                      City<span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      {...register("city", {
                        required: "Please Enter City.",
                      })}
                    />
                    {errors.city?.message && (
                      <span className="text-danger">
                        {errors.city?.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-6">
                    <label htmlFor="" className="form-label label">
                      Postal Code<span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="zip_code"
                      {...register("zip_code", {
                        required: "Please Enter Postal Code.",
                      })}
                    />
                    {errors.zip_code?.message && (
                      <span className="text-danger">
                        {errors.zip_code?.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label htmlFor="" className="form-label label">
                      State/Province<span>*</span>
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      id="state"
                      {...register("state", {
                        required: "Please Enter Postal Code.",
                      })}
                    />

                    {errors.state?.message && (
                      <span className="text-danger">
                        {errors.state?.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="form-group col-12 mb-5">
								<label htmlFor="" className="form-label label">
									Expected Ship Days<span>*</span>
								</label>
								<input
									type="text"
									className="form-control"
									id="supplier_exp_ship"
									{...register("supplier_exp_ship")}
								/>
							</div> */}
              {/* <div className="form-group col-12 mb-5">
								<label htmlFor="" className="form-label label">
									Order Memo<span>*</span>
								</label>
								<input
									type="text"
									className="form-control"
									id="supplier_order_memo"
									{...register("supplier_order_memo")}
								/>
							</div>
							<div className="form-group col-12 mb-5">
								<label htmlFor="" className="form-label label">
									Supplier Notes<span>*</span>
								</label>
								<input
									type="text"
									className="form-control"
									id="supplier_notes"
									{...register("supplier_notes")}
								/>
							</div> */}
              <div className="form-group col-12 mb-5">
                <label htmlFor="" className="form-label label">
                  Website<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="supplier_website"
                  {...register("supplier_website")}
                />
              </div>
              <div className="col-12 mb-4">
                <h6>Representative</h6>
              </div>
              <div className="form-group col-12 mb-5">
                <label htmlFor="" className="form-label label">
                  Representative Name<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="representative_name"
                  {...register("representative_name")}
                />
              </div>
              <div className="form-group col-12 mb-5">
                <label htmlFor="" className="form-label label">
                  Email<span>*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="representative_email"
                  {...register("representative_email")}
                />
              </div>
              <div className="form-group col-12 mb-5">
                <label htmlFor="" className="form-label label">
                  Phone Number<span>*</span>
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="Representative_contact"
                  {...register("Representative_contact")}
                />
              </div>
            </form>
          </div>
          <div className="panel-footer panel">
            <div className="row">
              <div className="col text-end">
                <button
                  type="submit"
                  className="btn btn-primary ms-2"
                  onClick={handleSubmit(updateHandler)}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierEditModal;
