import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../../Helper/ApiConstants";

const SupplierAddModal = ({ getSupplierList }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
  });

  //submit product form data
  const submitHandler = (data) => {
    axios
      .post(`${API_BASE_URL}/add-supplier`, data, HEADERS)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Supplier create successfully");
          getSupplierList();
          reset();
        }
      });
  };

  return (
    <div
      className="modal modalWrap slidePanel fade"
      id="newSupplier"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="panel-dialog modal-dialog small-dialog modal-dialog-scrollable panel-lg">
        <div className="panel-content modal-content">
          <div className="close-panel row g-o text-align-center align-middle">
            <div className="col">
              <h5 className="panel-title mb-0 p-0 text-white">
                <div
                  className="fal fa-times"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></div>
                <span className="ms-2">Add Supplier</span>
              </h5>
            </div>
          </div>
          <div className="modal-body panelBody">
            <form action="" className="row g-0">
              <div className="form-group col-12 mt-5 mb-5">
                <label htmlFor="" className="form-label label">
                  Supplier Name<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="supplier_name"
                  {...register("supplier_name", {
                    required: "Name is Required",
                  })}
                />
                {errors.supplier_name && (
                  <span className="text-danger">
                    {errors.supplier_name?.message}
                  </span>
                )}
              </div>
              <div className="form-group col-12 mb-5">
                <label htmlFor="supplier_email" className="form-label label">
                  Email<span>*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="supplier_email"
                  {...register("supplier_email", {
                    required: "Please Enter Email",
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Please enter valid email",
                    },
                  })}
                />
                {errors.supplier_email && (
                  <span className="text-danger">
                    {errors.supplier_email?.message}
                  </span>
                )}
              </div>
              <div className="form-group col-12 mb-5">
                <label htmlFor="" className="form-label label">
                  Phone Number<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="supplier_phone"
                  {...register("supplier_phone", {
                    required: "Please Enter Contact no.",
                    pattern: {
                      value: /^[0-9]*$/,
                      //value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                      message: "Please Enter Numbers Only",
                    },
                  })}
                />
                {errors.supplier_phone && (
                  <span className="text-danger">
                    {errors.supplier_phone?.message}
                  </span>
                )}
              </div>
              <div className="form-group col-12">
                <label htmlFor="" className="form-label label">
                  Address<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="supplier_address"
                  {...register("supplier_address", {
                    required: "Please Enter Address.",
                  })}
                />
                {errors.supplier_address && (
                  <span className="text-danger">
                    {errors.supplier_address?.message}
                  </span>
                )}
              </div>
              <div className="form-group col-12">
                <label htmlFor="" className="form-label label">
                  Apt, Suite, etc<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="supplier_fax"
                  {...register("supplier_fax")}
                />
              </div>
              <div className="detailAddress col-12 mb-5">
                <div className="row g-0">
                  <div className="form-group col-6 pe-3">
                    <label htmlFor="" className="form-label label">
                      City<span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="supplier_city"
                      {...register("supplier_city", {
                        required: "Please Enter City.",
                      })}
                    />
                    {errors.supplier_city && (
                      <span className="text-danger">
                        {errors.supplier_city?.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-6">
                    <label htmlFor="" className="form-label label">
                      Postal Code<span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="supplier_postalcode"
                      {...register("supplier_postalcode", {
                        required: "Please Enter Postal Code.",
                      })}
                    />
                    {errors.supplier_postalcode && (
                      <span className="text-danger">
                        {errors.supplier_postalcode?.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label htmlFor="" className="form-label label">
                      State/Province<span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="supplier_state"
                      {...register("supplier_state")}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group col-12 mb-5">
                <label htmlFor="" className="form-label label">
                  Expected Ship Days<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="supplier_exp_ship"
                  {...register("supplier_exp_ship")}
                />
              </div>
              <div className="form-group col-12 mb-5">
                <label htmlFor="" className="form-label label">
                  Order Memo<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="supplier_order_memo"
                  {...register("supplier_order_memo")}
                />
              </div>
              <div className="form-group col-12 mb-5">
                <label htmlFor="" className="form-label label">
                  Supplier Notes<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="supplier_notes"
                  {...register("supplier_notes")}
                />
              </div>
              <div className="form-group col-12 mb-5">
                <label htmlFor="" className="form-label label">
                  Website<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="supplier_website"
                  {...register("supplier_website", {
                    required: "supplier website is Required",
                    pattern: {
                      value:
                        /^((ftp|http|https):\/\/)?www\.([A-z]+)\.([A-z]{2,})/,
                      message: "Please enter a valid url",
                    },
                  })}
                />
                {errors.supplier_website && (
                  <span className="text-danger">
                    {errors.supplier_website?.message}
                  </span>
                )}
              </div>
              <div className="col-12 mb-4">
                <h6>Representative</h6>
              </div>
              <div className="form-group col-12 mb-5">
                <label htmlFor="" className="form-label label">
                  Representative Name<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="supplier_representative_name"
                  {...register("supplier_representative_name")}
                />
              </div>
              <div className="form-group col-12 mb-5">
                <label htmlFor="" className="form-label label">
                  Email<span>*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="supplier_representative_email"
                  {...register("supplier_representative_email", {
                    required: "Email is Required",
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Please enter valid email",
                    },
                  })}
                />
                {errors.supplier_representative_email && (
                  <span className="text-danger">
                    {errors.supplier_representative_email?.message}
                  </span>
                )}
              </div>
              <div className="form-group col-12 mb-5">
                <label htmlFor="" className="form-label label">
                  Phone Number<span>*</span>
                </label>
                <input
                  type="tel"
                  className="form-control"
                  id="supplier_representative_contact"
                  {...register("supplier_representative_contact", {
                    required: "Phone is Required",
                    pattern: {
                      //value: /^[0-9]*$/,
                      value: /[0-9]{3}-[0-9]{3}-[0-9]{4}/,
                      message: "Please Enter Numbers Only",
                    },
                  })}
                />
                {errors.supplier_representative_contact && (
                  <span className="text-danger">
                    {errors.supplier_representative_contact?.message}
                  </span>
                )}
              </div>
            </form>
          </div>
          <div className="panel-footer panel">
            <div className="row">
              <div className="col text-end">
                {/* <button className="btn btn-danger">Deactivate</button> */}
                <button
                  type="submit"
                  className="btn btn-primary ms-2"
                  onClick={handleSubmit(submitHandler)}
                  disabled={!isDirty || !isValid}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierAddModal;
