import React from "react";
import { UpperCase, Capitalize, ToFixed } from "../../../Helper/Utilites";

const ProductList = ({ product, openModal }) => {
  return (
    <div className="middlePart mt-3 col-12">
      <div className="row g-0 mt-2">
        <div className="col panel locBlock p-0">
          <table className="panelTable shadow fixed-layout table table-hover">
            <thead>
              <tr>
                <th className="businessName">
                  Product Title <i className="far fa-arrow-up"></i>
                </th>
                <th className="nickName">
                  brand <i className="far fa-arrow-up"></i>
                </th>
                <th className="staff">
                  price <i className="far fa-arrow-up"></i>
                </th>
                <th className="locManage">
                  units <i className="far fa-arrow-up"></i>
                </th>
                <th className="locManage">
                  supliers <i className="far fa-arrow-up"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              {product.map((pdata, index) => (
                <tr
                  key={index}
                  data-bs-toggle="modal"
                  data-bs-target={"#editProduct"}
                  onClick={() => openModal(pdata)}
                >
                  <td>{Capitalize(pdata.product_title)}</td>
                  <td>{pdata.brand}</td>
                  <td>{pdata.price}</td>
                  <td>{pdata.unit_cost}</td>
                  <td>{pdata.supplier_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
