import React from "react";
import { Capitalize, ToFixed } from "../../Helper/Utilites";

const AddonsList = ({ addons, openModal }) => {
	//console.log(addons);

	return (
		<table className="panelTable shadow shadow table table-hover">
			<thead>
				<tr>
					<th className="staffRole">Add-ons</th>
					<th className="staffRole">Descriptions</th>
					<th className="staffRole">Price</th>
					<th className="lastName">Operations</th>
				</tr>
			</thead>
			<tbody>
				{ addons.map((option, index) => (
					<tr
						key={index}
						data-bs-toggle="modal"
						data-bs-target={`#addonEdits`}
						className="staffRole"
						onClick={() => openModal(option)}
					>
						<td>{Capitalize(option.service_title)}</td>
						<td>{option.service_description}</td>
						<td>${ToFixed(Number(option.service_price))}</td>
						<td className="privilegeGroup col-2">
							<button
								className="btn btn-danger waves-effect waves-light btn-sm"
								type="button"
								data-bs-toggle="modal"
								data-bs-target={`#addonDelete`}
								onClick={() => openModal(option)}
							>Remove</button>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default AddonsList;
