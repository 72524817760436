import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../../Helper/ApiConstants";

const DeleteProCateModal = ({ modalId, getProductCategoryList }) => {
  const handleDelete = (id) => {
    const data = { pro_cat_id: id };

    axios
      .post(`${API_BASE_URL}/delete-product-category`, data, HEADERS)
      .then((res) => {
        if (res.status === 200) {
          toast.error("Product Category delete successfully");
          getProductCategoryList();
        }
      });
  };

  return (
    <div
      className="modal modalWrap fade"
      //id={"ModalDelete" + props.modalId}
      id="ModalDelete"
      tabIndex="-1"
      aria-labelledby="ModalDeleteLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="ModalDeleteLabel">
              Are you Sure?
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p>Are you Sure? want to delete Product Category.</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => handleDelete(modalId)}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteProCateModal;
