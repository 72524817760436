import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { API_BASE_URL, HEADERS,UPLOAD_URL } from "../../Helper/ApiConstants";
import axios from "axios";

// import Board from 'react-trello';
import { withRouter } from "react-router-dom";
import StatusBar from "../../components/statusBar/StatusBar";


// import Kanban from "../../components/Kanban/Kanban";
//import axios from "axios";


import "./Trello.css";

import TrelloBoard from "./TrelloBoard";
import BookingDetails from "./BookingDetails";



const initialLists = [
	{
		title: "Waiting",
		status: "todo"
	},
	{
		title: "Confirm",
		status: "doing"
	},
	{
		title: "Complete",
		status: "done"
	},
	{
		title: "Unconfirmed",
		status: "unconfirmed"
	},
	{
		title: "Walk in",
		status: "walkin"
	},
	{
		title: "Reschedule",
		status: "reschedule"
	}	
];

const initialData = {
	todo: [
		{
			id: "qwe1",
			title: "Card 1",
			status: "todo",
			order: 1,
			label: "UI Dev"
		},
		{
			id: "qwe3",
			title: "Card 3",
			status: "todo",
			order: 2,
			label: "UI Dev"
		},
		{
			id: "qwe5",
			title: "Card 5",
			status: "todo",
			order: 3,
			label: "Testing"
		}
	],
	doing: [
		{
			id: "qwe2",
			title: "Card 2",
			status: "doing",
			order: 1,
			label: "API Integration"
		}
	],
	done: [
		{
			id: "qwe4",
			title: "Card 4",
			status: "done",
			order: 1,
			label: "Bug Fix"
		}
	],
	unconfirmed: [
		{
			id: "qwe4",
			title: "Card 4",
			status: "unconfirmed",
			order: 1,
			label: "Bug Fix"
		}
	],
	walkin: [
		{
			id: "qwe4",
			title: "Card 4",
			status: "walkin",
			order: 1,
			label: "Bug Fix"
		}
	],
	reschedule: [
		{
			id: "qwe4",
			title: "Card 4",
			status: "reschedule",
			order: 1,
			label: "Bug Fix"
		}
	]
};




const Dashboard = () => {

	const [lists, setLists] = useState(initialLists);
	const [data, setData] = useState(initialData);
	const [modalid, setModalId] = useState('');
	

	const [bookings_list, setBookingsList] = useState([]);

	const [bookings, setBookings] = useState([]);

	var location_id = 22;

	// Handle Lists
	// Handle Lists ends here

	// Handle Data
	// const cardChangeHandler = (cardInfo, newStatus, targetCardId) => {
	// 	//console.log(cardInfo, newStatus, targetCardId);
	// 	const { id, status: oldStatus } = cardInfo;

	// 	let dropCard = data[oldStatus].find((el) => el.id === id);
	// 	let targetCard =
	// 		targetCardId !== ""
	// 			? data[newStatus].find((el) => el.id === targetCardId)
	// 			: null;

	// 	let newListOrderValueMax = data[newStatus]
	// 		.map((item) => item.order)
	// 		.reduce((maxValue, a) => Math.max(maxValue, a), 0);

	// 	// CASE 1: If same list, work only this if block then return;
	// 	if (oldStatus === newStatus) {
	// 		let temp = data[oldStatus]
	// 			.map((item) => {
	// 				if (item.id === dropCard.id)
	// 					return {
	// 						...dropCard,
	// 						order: targetCard
	// 							? targetCard.order - 1
	// 							: newListOrderValueMax + 1
	// 					};
	// 				return item;
	// 			})
	// 			.sort((a, b) => a.order - b.order)
	// 			.map((item, i) => {
	// 				return { ...item, order: i + 1 };
	// 			});
	// 		setData((d) => {
	// 			return { ...d, [oldStatus]: temp };
	// 		});

	// 		return;
	// 	}
	// 	// CASE 1 ENDS HERE

	// 	// CASE 2: Drag across multiple lists
	// 	let tempGaveList = data[oldStatus]
	// 		.filter((item) => item.id !== id)
	// 		.sort((a, b) => a.order - b.order)
	// 		.map((item, i) => {
	// 			return { ...item, order: i + 1 };
	// 		});

	// 	let tempRecievedList = [
	// 		...data[newStatus],
	// 		{
	// 			...dropCard,
	// 			order: targetCard ? targetCard.order - 1 : newListOrderValueMax + 1
	// 		}
	// 	]
	// 		.sort((a, b) => a.order - b.order)
	// 		.map((item, i) => {
	// 			return { ...item, order: i + 1 };
	// 		});

	// 	// At last, set state
	// 	setData((d) => {
	// 		return { ...d, [oldStatus]: tempGaveList, [newStatus]: tempRecievedList };
	// 	});

	// 	// CASE 2 ENDS HERE
	// };
	// Handle Data ends here


const openModel = (id) => {

	
	console.log("open model========", id);
	

	
};



const cardChangeHandler1 = (cardInfo, newStatus, targetCardId) => {

		const { id, status: oldStatus } = cardInfo;

		updateBookingStatus(id, newStatus);



		let dropCard = bookings[oldStatus].find((el) => el.id === id);
		let targetCard =
			targetCardId !== ""
				? bookings[newStatus].find((el) => el.id === targetCardId)
				: null;

		let newListOrderValueMax = bookings[newStatus]
			.map((item) => item.order)
			.reduce((maxValue, a) => Math.max(maxValue, a), 0);

		// CASE 1: If same list, work only this if block then return;
		if (oldStatus === newStatus) {
			let temp = bookings[oldStatus]
				.map((item) => {
					if (item.id === dropCard.id)
						return {
							...dropCard,
							order: targetCard
								? targetCard.order - 1
								: newListOrderValueMax + 1
						};
					return item;
				})
				.sort((a, b) => a.order - b.order)
				.map((item, i) => {
					return { ...item, order: i + 1 };
				});
			setData((d) => {
				return { ...d, [oldStatus]: temp };
			});

			return;
		}
		// CASE 1 ENDS HERE

		// CASE 2: Drag across multiple lists
		let tempGaveList = bookings[oldStatus]
			.filter((item) => item.id !== id)
			.sort((a, b) => a.order - b.order)
			.map((item, i) => {
				return { ...item, order: i + 1 };
			});

		let tempRecievedList = [
			...bookings[newStatus],
			{
				...dropCard,
				order: targetCard ? targetCard.order - 1 : newListOrderValueMax + 1
			}
		]
			.sort((a, b) => a.order - b.order)
			.map((item, i) => {
				return { ...item, order: i + 1 };
			});

		// At last, set state
		setBookings((d) => {
			return { ...d, [oldStatus]: tempGaveList, [newStatus]: tempRecievedList };
		});

		// CASE 2 ENDS HERE
	};

const openModal = (mdl_id) => {
	alert();

}

	const updateBookingStatus = (booking_id, booking_status) => {

		var data = {booking_id:booking_id, booking_status:booking_status}

		axios
			.post(`${API_BASE_URL}/update-booking-status/`,data,HEADERS)
			.then(function (response) {
				if (response.status === 200) {

					
				}
			});
	};


	const bookingDetailsModal = (booking_id) => {

		console.log("adsfasdfasdf asdf======",booking_id);

		var data = {booking_id:booking_id}

		axios
			.get(`${API_BASE_URL}/booking_detail/`+booking_id,HEADERS)
			.then(function (response) {
				if (response.status === 200) {

					
				}
			});
	};


	

	const getClientsBookings = () => {
		axios
			.get(`${API_BASE_URL}/clients-bookings/`+location_id, HEADERS)
			.then(function (response) {
				if (response.status === 200) {

					setBookingsList(response.data.status_list);
					setBookings(response.data.bookings1);

				}
			});
	};	

	useEffect(() => {
		getClientsBookings();
		
	}, []);



	return (
	<div className="mainWrapper">
		<Header />
			{/*  <!-- Status Bar --> */}
			<StatusBar />
			{/*  <!-- Kanban --> */}
			{/*<Kanban />*/}
			
			{/*<Board data={data}  />*/}

		
			{/*<Board data={data} { ...settings }
						 handleDragStart={handleDragStart}
						 handleDragEnd={handleDragEnd}/>*/}

			<div className="app-board allboard bodyWrap" 
					style= { {background: "rgba(0, 0, 0, 0) linear-gradient(to right, rgb(48, 213, 200), rgb(255, 255, 0)) repeat scroll 0% 0%"} } >
				
				<div className="row">
					<div className="board-body bookings-list">

						{ bookings_list.map( (booking,i) => (
							
								<div className="board" key={"sdfsdf"+i}>
									<div className="fullWrap"  key={"ddd"+i}>
										
										<TrelloBoard
											data={bookings[booking.status]}
											key={booking.status}
											title={booking.title}
											status={booking.status}
											onChange={cardChangeHandler1}
											bookingDetailsModal = {bookingDetailsModal}
											
										/>

									</div>
								</div>
							))
						}
					</div>
					{/*
						<button type="button" className="btn btn-primary" data-mdb-toggle="modal" data-mdb-target="#exampleModal">
						  Launch demo modal
						</button>
					*/}

					<div className="modal cardDetails fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog">
							<div className="modal-content">
								<header className="upper">
									<h4 className="styName">Alexa D’Agostino <small>(908) 907-1219</small></h4>
									<div className="rate">
										<div className="showRate"><strong>Show Rate :</strong> 100% </div>
										<div className="showRate avRate"><strong>Avg. Visit :</strong> $166 </div>
									</div>
									<div className="notify">
										<div className="snglNot">
											🔔
											<div className="fullDetail">
												<span>email reminder send Thu Dec 2 @ 11.34am</span>
												<span>sms reminder send Wed Dec 1 @ 5:17pm</span>
											</div>
										</div>
									</div>
								</header>
								<div className="modal-body1">

								
									<div className="cardDetBody">
										<div className="schDate"> <small>Wed, Aug 18th, 11:00am - 12:40pm</small> </div>
										<table className="table services">
											<tbody>
												<tr>
													<td className="time">11:00am</td>
													<td className="serName">Medium/ Long Cut</td>
													<td className="styName" width="90">Candis <i className="icon">🤍</i></td>
												</tr>
												<tr>
													<td className="time">12:00am</td>
													<td className="serName">Toner / Gloss - Without Blowout </td>
													<td className="styName" width="90">Ashley <i className="icon">❤️</i></td>
												</tr>
											</tbody>
										</table>
										<div className="toPrice">
											$128.00 <i className="fas fa-check-circle"></i>
											<small>Booked by Nicole on May 19th @ 1:16pm</small>
										</div>

										<div className="tagArea">
											<div className="tagLabel">Appointment Tags</div>
											<div className="tagsWrap">
												<div className="sngTag cover">
													<span>✅</span> Confirmation Text Sent <i className="far fa-times"></i>                                                        
												</div>
												<div className="sngTag add">
													<a className="collapsed addTag" data-bs-toggle="collapse" href="#allTags" role="button" aria-expanded="false" aria-controls="allTags">Add Tags +</a> 
													<div id="allTags" className="addWrap collapse">
														<div className="tagSearch">
															<input type="search" name="tagSearch" placeholder="Search Tags" />
															<button type="button"><i className="far fa-search"></i></button>
														</div>
														<div className="allTags ">
															<div className="snglTag">
																<div className="tagIcon">✂️</div>
																<div className="tagDescr">Appointment Adjustment</div>
															</div>
															<div className="snglTag">
																<div className="tagIcon">🐢</div>
																<div className="tagDescr">Running Late</div>
															</div>
															<div className="snglTag">
																<div className="tagIcon">😷</div>
																<div className="tagDescr">Covid Closure</div>
															</div>
															<div className="snglTag">
																<div className="tagIcon">🖌️</div>
																<div className="tagDescr">Color Corrections</div>
															</div>
															<div className="snglTag">
																<div className="tagIcon">✅</div>
																<div className="tagDescr">Confirmation Text Sent</div>
															</div>
															<div className="tagDescr">Jordan Cares</div>
														</div>
														<div className="snglTag">
															<div className="tagIcon">⚠️</div>
															<div className="tagDescr">Late Cancelation</div>
														</div>
														<div className="snglTag">
															<div className="tagIcon">💕</div>
															<div className="tagDescr">Loyal Client</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<footer className="lower">
										<div className="cancel">
											<a href="#" className=""><i className="fal fa-times-circle"></i> <span>Cancel</span></a>                                                
										</div>
									</footer>

								</div>
								<div className="modal-footer">
									<a href="#" className="btn btn-primary"><i className="fal fa-check"></i> <span>Mark as Confirmed</span></a>                                  
									<a href="#" className="btn btn-secondary"><i className="fal fa-pencil"></i> <span>Update</span></a>
								</div>
							</div>
						</div>
					</div>

					{/*<BookingDetails modal_id={modalid} openModal={openModal} />*/}
					
				</div>
			</div>

		https://codesandbox.io/s/drag-and-drop-trello-forked-3nkvzw?file=/src/pages/Trello.js

		
	</div>
	);
};

export default withRouter(Dashboard);
