import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";
import { withRouter } from "react-router-dom";
//import { toast, ToastContainer } from "react-toastify";
import Header from "../../components/Header/Header";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import EditStaffModal from "../../components/StaffRoles/EditStaffModal/EditStaffModal";
import AddStaffModal from "../../components/StaffRoles/AddStaffModal/AddStaffModal";
import StaffRolesDeleteModal from "../../components/StaffRoles/StaffRolesDeleteModal";
import { UpperCase, Capitalize, ToFixed } from "../../Helper/Utilites";

const StaffRoles = () => {
	const [roles, setRoles] = useState([]);
	const [rolesedata, setRolesdata] = useState([]);

	//Get Roles list
	const ListStaffRoles = () => {
		axios
			.get(API_BASE_URL + "/staff-roles-list", HEADERS)
			.then(function (response) {
				if (response.status === 200) {
					setRoles(response.data);
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const openModal = (data) => {
		setRolesdata(data);
	};

	useEffect(() => {
		ListStaffRoles();
	}, []);

	return (
		<React.Fragment>
			<Header />
			{/* <ToastContainer /> */}
			{/*<!-- Wrapper --> */}
			<div id="settings" className="mainWrapper">
				{/* <!-- Status Bar --> */}
				<div id="settingsBar" className="statusBar settingsBar container-fluid">
					<div className="leftSide pageTitle">
						<h4 className="barTitle">Manage Roles</h4>
					</div>
					<div className="rightSide AddNewDiscount">
						<button
							className="btn btn-primary purple"
							data-bs-toggle="modal"
							data-bs-target="#addNewEmployee"
						>Add Roles</button>
					</div>
				</div>
				{/* <!--End of Status Bar --> */}
				<div
					id="setPanelWrap"
					className="setWrap panelWrap bodyWrap p-0 container-fluid"
				>
					<div className="row m-0 g-0 justify-content-end">
						<div id="left" className="serMenu leftSide sideBarMenu p-0 col">
							<LeftSidebar />
						</div>
						<div
							id="right"
							className="rightSide col panel busiDetails setBodyDetails"
						>
							<div className="row g-0">
								<div className="topPart col-12">
									<div className="row align-items-center">
										<div className="panelHead col">
											<h3 className="mb-0">
												<i className="fas fa-circle success"></i> Active Roles
											</h3>
										</div>
										<div className="col-3 right filterSearch staffInvite text-end">
											<button className="btn btn-simple p-0">
												Active<i className="fas fa-chevron-down"></i>
											</button>
											<button className="btn btn-simple p-0 ms-3 me-3">
												<i className="far fa-search"></i>
											</button>
											<button className="btn btn-default">Invite Staff</button>
										</div>
									</div>
								</div>
								<div className="middlePart mt-3 col-12">
									<div className="row g-0 mt-2">
										<div className="col panel serblock p-0">
											<table className="panelTable shadow shadow table table-hover">
												<thead>
													<tr>
														<th className="staffRole">
															Staff Role <i className="far fa-arrow-up"></i>
														</th>
														<th className="lastName">
															Operations <i className="far fa-arrow-up"></i>
														</th>
													</tr>
												</thead>
												<tbody>
													{roles.map((role, index) => (
														<tr key={index}>
															<td
																data-bs-toggle="modal"
																data-bs-target={`#staffroleEdits`}
																className="staffRole"
																onClick={() => openModal(role)}
															>
																{Capitalize(role.staff_roles)}
															</td>
															<td className="privilegeGroup col-2">
																<button
																	type="button"
																	className="btn btn-danger waves-effect waves-light btn-sm"
																	data-bs-toggle="modal"
																	data-bs-target={`#ModalDelete`}
																	onClick={() => openModal(role)}
																>
																	Remove
																</button>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<AddStaffModal ListStaffRoles={ListStaffRoles} />

			<EditStaffModal role={rolesedata} ListStaffRoles={ListStaffRoles} />
			<StaffRolesDeleteModal
				modalId={rolesedata.id}
				ListStaffRoles={ListStaffRoles}
			/>

			{/* End of Wrapper */}
		</React.Fragment>
	);
};

export default withRouter(StaffRoles);
