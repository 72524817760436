import React, { useEffect } from "react";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../../Helper/ApiConstants";
import Select from "react-select";

function UpdateProductmodal({ productData, getProductList, supplier }) {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: productData,
  });

  const updateProductHandler = (data) => {
    axios
      .post(API_BASE_URL + "/update-product", data, HEADERS)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Product Update successfully");

          getProductList();
        }
      });
  };

  useEffect(() => {
    reset(productData);
  }, [productData]);

  return (
    <div
      className="modal modalWrap slidePanel fade"
      id={"editProduct"}
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="panel-dialog modal-dialog modal-dialog-scrollable panel-lg">
        <div className="panel-content modal-content">
          <div className="close-panel align-middle">
            <button
              className="btn btn-simple p-0 white"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <div className="fal fa-times"></div> Close
            </button>
          </div>
          <div className="panel-header">
            <h5 className="panel-title pb-0">In Salon Consulation</h5>
            <div className="tabOptions  panelTabs">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <a
                    className="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    href="#overview"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Overview
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="modal-body panelBody">
            <div className="tab-content tabContent" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="overview"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="row mt-5">
                  <form>
                    <div className="row g-0 justify-content-center mt-4 mb-4">
                      <div className="form-group col-6 pe-2">
                        <label
                          htmlFor="product_title"
                          className="form-label label"
                        >
                          Product Title<span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Title"
                          id="product_title"
                          {...register("product_title", {
                            required: true,
                          })}
                        />
                        {errors.product_title && (
                          <span className="text-danger">
                            Product Title Required
                          </span>
                        )}
                      </div>

                      <div className="form-group col-6">
                        <label
                          htmlFor="product_size"
                          className="form-label label"
                        >
                          Size
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="grams or inches etc."
                          id="product_size"
                          {...register("product_size")}
                        />
                      </div>
                    </div>
                    <div className="row g-0 justify-content-center mt-4 mb-4">
                      <div className="form-group col-6">
                        <label htmlFor="color" className="form-label label">
                          color
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="color"
                          id="color"
                          {...register("color")}
                        />
                      </div>
                      <div className="form-group col-6">
                        <label htmlFor="barcode" className="form-label label">
                          barcode
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="barcode"
                          id="barcode"
                          {...register("barcode")}
                        />
                      </div>
                    </div>

                    <div className="row g-0 justify-content-center mt-4 mb-4">
                      <div className="form-group col-6">
                        <label
                          htmlFor="product_description"
                          className="form-label label"
                        >
                          description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="description"
                          id="product_description"
                          {...register("product_description")}
                        />
                      </div>
                      <div className="form-group col-6">
                        <label
                          htmlFor="product_price"
                          className="form-label label"
                        >
                          price <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="price"
                          id="price"
                          {...register("price", { required: true })}
                        />
                        {errors.price && (
                          <span className="text-danger">
                            Product Price Required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row g-0 justify-content-center mt-4 mb-4">
                      <div className="form-group col-6">
                        <label htmlFor="unit_cost" className="form-label label">
                          Unit cost <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Unit cost"
                          id="unit_cost"
                          {...register("unit_cost", {
                            required: true,
                          })}
                        />
                        {errors.unit_cost && (
                          <span className="text-danger">
                            Product Unit Cost Required
                          </span>
                        )}
                      </div>
                      <div className="form-group col-6">
                        <label
                          htmlFor="product_brand"
                          className="form-label label"
                        >
                          brand<span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="brand"
                          id="brand"
                          {...register("brand", { required: true })}
                        />
                        {errors.brand && (
                          <span className="text-danger">
                            Product brand Required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row g-0 justify-content-center mt-4 mb-4">
                      <div className="form-group col-6">
                        <label htmlFor="sku" className="form-label label">
                          Sku
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Sku"
                          id="sku"
                          {...register("sku")}
                        />
                      </div>

                      <div className="form-group col-6">
                        <label htmlFor="supliers" className="form-label label">
                          Select supplier...
                        </label>
                        <Controller
                          name="supliers_id"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <Select
                              options={supplier}
                              value={supplier.find((c) => c.value === value)}
                              onChange={(val) => onChange(val.value)}
                            />
                          )}
                          rules={{ required: "This is required" }}
                        />

                        {/* <select
                          //multiple
                          className="mdb-select md-form form-select"
                          value={productData.supliers_id}
                          id="supliers"
                          {...register("supliers")}
                        >
                          <option value="default">Option...</option>
                          {supplier.map((option) => (
                            <option value={option.value} key={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select> */}
                      </div>
                    </div>

                    <div className="row g-0 justify-content-center mb-4">
                      <div className="form-check checkbox">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="charge_tax"
                          {...register("charge_tax", { required: false })}
                        />

                        <label
                          className="form-check-label"
                          htmlFor="charge_tax"
                        >
                          charge tax on this item.
                        </label>
                      </div>
                    </div>
                    <div className="row g-0 justify-content-center mb-4">
                      <div className="form-check checkbox">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="is_for_service"
                          {...register("is_for_service")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is_for_service"
                        >
                          this product can be used when performing a service.
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="panel-footer panel">
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  // onClick={() => deleteProductHandler(props.modalId)}
                >
                  Delete
                </button>
              </div>
              <div className="col text-end">
                <button
                  className="btn btn-primary"
                  //data-bs-dismiss="modal"
                  onClick={handleSubmit(updateProductHandler)}
                >
                  Update Product
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateProductmodal;
