import axios from "axios";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../../Helper/ApiConstants";

const AddStaffModal = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({ /* mode: "onChange", */ mode: "onTouched" });

  //console.log(errors);

  const onSubmit = (data) => {
    console.log(data);
    axios
      .post(`${API_BASE_URL}/add-staff`, data, HEADERS)
      .then((response) => {
        if (response.status === 200) {
          toast.success("staff role successfully");
          reset();
          props.ListStaffRoles();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div
      className="modal modalWrap fade"
      id="addNewEmployee"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable panel-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              <strong>New Role</strong>
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body modalBody row align-items-center">
            <div className="col-12">
              <form>
                <div className="row g-0 justify-content-center mt-4 mb-4">
                  <div className="form-group col-6 pe-2">
                    <label htmlFor="roll" className="form-label label">
                      Enter Role <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="roll"
                      {...register("roll", { required: "please enter roll" })}
                    />
                    {errors.roll && (
                      <span className="text-danger">
                        {errors.roll?.message}
                      </span>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer row g-0">
            <div className="col text-center">
              <button
                type="button"
                className="btn btn-primary btn-lg"
                onClick={handleSubmit(onSubmit)}
                disabled={!isDirty || !isValid}
              >
                Create Role
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStaffModal;
