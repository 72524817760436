import React from "react";

import TrelloCard from "./TrelloCard";

export default function TrelloBoard({ data, title, status, onChange,bookingDetailsModal }) {
	// Sort data (Might need useMemo)


	if(!data){
		return '';
	}
	let sorted = data.sort((a, b) => a.order - b.order);

	const onDragEnterHandler = (e) => {
		e.preventDefault();
	};
	const onDragOverHandler = (e) => {

		//console.log(e.target.className);
		e.preventDefault(); 
		if (e.target.className === "boardContentArea" || e.target.className === "card" || e.target.className === "cardTitle" || e.target.className === "cardFooter") {
			
			//console.log(e.target.className);

			setTimeout(() => {
				e.target.className = "boardContentArea hovered";
			}, 0);
		}
	};
	const onDragLeaveHandler = (e) => {
		e.preventDefault();
		if (e.target.className === "boardContentArea hovered") {
			setTimeout(() => {
				e.target.className = "boardContentArea";
			}, 0);
		}
	};
	const onDropHandler = (e) => {
		let cardInfo = JSON.parse(e.dataTransfer.getData("cardInfo"));
		let targetCardId = e.target.id;
		onChange(cardInfo, status, targetCardId);
		if (e.target.className === "boardContentArea hovered") {
			setTimeout(() => {
				e.target.className = "boardContentArea";
			}, 0);
		}
	};

	// returns JSX - Render cards
	const renderCards = () => {
		return sorted.map((item) => (
			<TrelloCard
				key={`status-${item.id}`}
				id={item.id}
				status={status}
				title={item.first_name}
				label={item.last_name}
				booking_datetime={item.booking_date_time}
				showBookingDetails = {showBookingDetails}
			/>
		));
	};



	const showBookingDetails = (id) =>{
		bookingDetailsModal(id);

		console.log(id ,"===============");

	}

	return (
		<div className="cardsWrap1">
			<div className="list">
				<h4 className="boardTitle">{title}</h4>
				<div
					className="boardContentArea cardsWrap"
					onDragEnter={onDragEnterHandler}
					onDragOver={onDragOverHandler}
					onDragLeave={onDragLeaveHandler}
					onDrop={onDropHandler}
				>
					{renderCards()}


				</div>
				
			</div>

		</div>
	);
}
