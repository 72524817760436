import React, { useState, useEffect, reset } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../../Helper/ApiConstants";
const EditStaffModal = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  function updateHandler(data) {
    axios
      .post(`${API_BASE_URL}/update-staff`, data, HEADERS)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Staff-role Update successfully");
          props.ListStaffRoles();
        }
      });
  }

  useEffect(() => {
    reset(props.role);
  }, [props.role]);

  return (
    <div
      className="modal modalWrap slidePanel fade"
      id="staffroleEdits"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="panel-dialog modal-dialog small-dialog modal-dialog-scrollable panel-lg">
        <div className="panel-content modal-content">
          <div className="close-panel row g-o text-align-center align-middle">
            <div className="col">
              <h5 className="panel-title mb-0 p-0 text-white">
                <div
                  className="fal fa-times"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></div>
                <span className="ms-2">Edit Supplier</span>
              </h5>
            </div>
          </div>
          <div className="modal-body panelBody">
            <form action="" className="row g-0">
              <div className="form-group col-12 mt-5 mb-5">
                <label htmlFor="" className="form-label label">
                  Supplier Name<span>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="staff_roles"
                  {...register("staff_roles", {
                    required: "Name is Required",
                  })}
                />
                {errors.staff_roles && (
                  <span className="text-danger">
                    {errors.staff_roles.message}
                  </span>
                )}
              </div>
            </form>
          </div>
          <div className="panel-footer panel">
            <div className="row">
              <div className="col text-end">
                <button
                  type="submit"
                  className="btn btn-primary ms-2"
                  onClick={handleSubmit(updateHandler)}
                >
                  Update Role
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditStaffModal;
