const RightStatusBar = () => {
	return (
		<div className="rightSide wait&appoint">
			<div className="staffList">
				<div
					className="mainBtn"
					data-bs-toggle="collapse"
					href="#staffFullList"
					role="button"
					aria-expanded="false"
					aria-controls="staffFullList"
				>
					<div className="avtar">
						<div className="snglAvtar">
							<span
								style={{
									borderColor: "#eaedf9",
									color: "#fff",
									background: "#f285f2",
								}}
							>
								<i className="fas fa-user"></i>
							</span>
						</div>
						<div className="snglAvtar">
							<span
								style={{
									borderColor: "#eaedf9",
									color: "#fff",
									background: "#a190ff",
								}}
							>
								<i className="fas fa-user"></i>
							</span>
						</div>
						<div className="snglAvtar">
							<span
								style={{
									borderColor: "#eaedf9",
									color: "#fff",
									background: "#f78bb9",
								}}
							>
								<i className="fas fa-user"></i>
							</span>
						</div>
						<div className="snglAvtar">
							<span
								style={{
									borderColor: "#eaedf9",
									color: "#fff",
									background: "#31d5c7",
								}}
							>
								<i className="fas fa-user"></i>
							</span>
						</div>
						<div className="snglAvtar">
							<span
								style={{
									borderColor: "#eaedf9",
									color: "#fff",
									background: "#ff5a79",
								}}
							>
								<i className="fas fa-user"></i>
							</span>
						</div>
					</div>
					<div className="btn btn-simple">
						{" "}
						+56 more <i className="fas fa-chevron-down"></i>
					</div>
				</div>
				<div id="staffFullList" className="staffFullList collapse">
					<div className="head">
						<div className="groHeader">
							<label>Select</label>
							<a href="foo" className="allStaff selBtn">All Staff							</a>
						</div>
					</div>
					<div className="listBody">
						<div className="groHeader">
							<label>Hari Stylist</label>
							<a href="foo" className="allStaff selBtn">
								Select All
							</a>
						</div>
						<div className="stfListGroup">
							<div className="snglStaff">
								<div className="avtar">
									<div className="snglAvtar">
										<span
											style={{
												borderColor: "#eaedf9",
												color: "#fff",
												background: "#f285f2",
											}}
										>
											<i className="fas fa-user"></i>
										</span>
									</div>
									<div className="avtName"> Alina Kravchenko </div>
								</div>
								<a href="foo" className="allStaff selBtn">
									Only
								</a>
							</div>
							<div className="snglStaff">
								<div className="avtar">
									<div className="snglAvtar">
										<span
											style={{
												borderColor: "#eaedf9",
												color: "#fff",
												background: "#a190ff",
											}}
										>
											{" "}
											<i className="fas fa-user"></i>
										</span>
									</div>
									<div className="avtName"> Alina Kravchenko </div>
								</div>
								<a href="foo" className="allStaff selBtn">
									Only
								</a>
							</div>
							<div className="snglStaff">
								<div className="avtar">
									<div className="snglAvtar">
										<span
											style={{
												borderColor: "#eaedf9",
												color: "#fff",
												background: "#f78bb9",
											}}
										>
											<i className="fas fa-user"></i>
										</span>
									</div>
									<div className="avtName"> Alina Kravchenko </div>
								</div>
								<a href="foo" className="allStaff selBtn">
									Only
								</a>
							</div>
							<div className="snglStaff">
								<div className="avtar">
									<div className="snglAvtar">
										<span
											style={{
												borderColor: "#eaedf9",
												color: "#fff",
												background: "#31d5c7",
											}}
										>
											<i className="fas fa-user"></i>
										</span>
									</div>
									<div className="avtName"> Alina Kravchenko </div>
								</div>
								<a href="foo" className="allStaff selBtn">
									Only
								</a>
							</div>
							<div className="snglStaff">
								<div className="avtar">
									<div className="snglAvtar">
										<span
											style={{
												borderColor: "#eaedf9",
												color: "#fff",
												background: "#ff5a79",
											}}
										>
											<i className="fas fa-user"></i>
										</span>
									</div>
									<div className="avtName"> Alina Kravchenko </div>
								</div>
								<a href="foo" className="allStaff selBtn">
									Only
								</a>
							</div>
							<div className="snglStaff">
								<div className="avtar">
									<div className="snglAvtar">
										<span
											style={{
												borderColor: "#eaedf9",
												color: "#fff",
												background: "#f285f2",
											}}
										>
											<i className="fas fa-user"></i>
										</span>
									</div>
									<div className="avtName"> Alina Kravchenko </div>
								</div>
								<a href="foo" className="allStaff selBtn">
									Only
								</a>
							</div>
							<div className="snglStaff">
								<div className="avtar">
									<div className="snglAvtar">
										<span
											style={{
												borderColor: "#eaedf9",
												color: "#fff",
												background: "#a190ff",
											}}
										>
											<i className="fas fa-user"></i>
										</span>
									</div>
									<div className="avtName"> Alina Kravchenko </div>
								</div>
								<a href="foo" className="allStaff selBtn">
									Only
								</a>
							</div>
							<div className="snglStaff">
								<div className="avtar">
									<div className="snglAvtar">
										<span
											style={{
												borderColor: "#eaedf9",
												color: "#fff",
												background: "#f78bb9",
											}}
										>
											<i className="fas fa-user"></i>
										</span>
									</div>
									<div className="avtName"> Alina Kravchenko </div>
								</div>
								<a href="foo" className="allStaff selBtn">
									Only
								</a>
							</div>
							<div className="snglStaff">
								<div className="avtar">
									<div className="snglAvtar">
										<span
											style={{
												borderColor: "#eaedf9",
												color: "#fff",
												background: "#31d5c7",
											}}
										>
											<i className="fas fa-user"></i>
										</span>
									</div>
									<div className="avtName"> Alina Kravchenko </div>
								</div>
								<a href="foo" className="allStaff selBtn">
									Only
								</a>
							</div>
							<div className="snglStaff">
								<div className="avtar">
									<div className="snglAvtar">
										<span
											style={{
												borderColor: "#eaedf9",
												color: "#fff",
												background: "#ff5a79",
											}}
										>
											<i className="fas fa-user"></i>
										</span>
									</div>
									<div className="avtName"> Alina Kravchenko </div>
								</div>
								<a href="foo" className="allStaff selBtn">
									Only
								</a>
							</div>
						</div>
						<div className="groHeader">
							<label>Service Provider</label>
							<a href="foo" className="allStaff selBtn">
								Select All
							</a>
						</div>
						<div className="stfListGroup">
							<div className="snglStaff">
								<div className="avtar">
									<div className="snglAvtar">
										<span
											style={{
												borderColor: "#eaedf9",
												color: "#fff",
												background: "#f285f2",
											}}
										>
											{" "}
											<i className="fas fa-user"></i>
										</span>
									</div>
									<div className="avtName"> Alina Kravchenko </div>
								</div>
								<a href="foo" className="allStaff selBtn">
									Only
								</a>
							</div>
							<div className="snglStaff">
								<div className="avtar">
									<div className="snglAvtar">
										<span
											style={{
												borderColor: "#eaedf9",
												color: "#fff",
												background: "#a190ff",
											}}
										>
											<i className="fas fa-user"></i>
										</span>
									</div>
									<div className="avtName"> Alina Kravchenko </div>
								</div>
								<a href="foo" className="allStaff selBtn">
									Only
								</a>
							</div>
							<div className="snglStaff">
								<div className="avtar">
									<div className="snglAvtar">
										<span
											style={{
												borderColor: "#eaedf9",
												color: "#fff",
												background: "#f78bb9",
											}}
										>
											<i className="fas fa-user"></i>
										</span>
									</div>
									<div className="avtName"> Alina Kravchenko </div>
								</div>
								<a href="foo" className="allStaff selBtn">
									Only
								</a>
							</div>
							<div className="snglStaff">
								<div className="avtar">
									<div className="snglAvtar">
										<span
											style={{
												borderColor: "#eaedf9",
												color: "#fff",
												background: "#31d5c7",
											}}
										>
											<i className="fas fa-user"></i>
										</span>
									</div>
									<div className="avtName"> Alina Kravchenko </div>
								</div>
								<a href="foo" className="allStaff selBtn">
									Only
								</a>
							</div>
							<div className="snglStaff">
								<div className="avtar">
									<div className="snglAvtar">
										<span
											style={{
												borderColor: "#eaedf9",
												color: "#fff",
												background: "#ff5a79",
											}}
										>
											<i className="fas fa-user"></i>
										</span>
									</div>
									<div className="avtName"> Alina Kravchenko </div>
								</div>
								<a href="foo" className="allStaff selBtn">
									Only
								</a>
							</div>
						</div>
					</div>
					<div className="foot filSearch">
						<form action="">
							<div className="form-group">
								<input
									type="search"
									className="form-control"
									placeholder="Type name or role..."
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
			<div className="waitList">
				<a href="foo" className="btn btn-default">
					<i className="far fa-users"></i> +99 Waiting
				</a>
			</div>
			<div className="newAppointment">
				<a href="foo" className="btn btn-primary">
					<i className="far fa-paper-plane"></i> New Appointment
				</a>
			</div>
		</div>
	);
};

export default RightStatusBar;
