import React from "react";
import { Link } from "react-router-dom";
import { UpperCase, Capitalize, ToFixed } from "../../Helper/Utilites";

const ServicesList = ({ categoriesList, openModal }) => {
  return (
    <>
      {categoriesList.map((category, index) => (
        <div className="row g-0 mt-2" key={category.id}>
          <div className="col panel serblock p-0">
            <table className="panelTable shadow shadow table table-hover">
              <thead>
                <tr>
                  <th>{UpperCase(category.category_name)}</th>
                  <th style={{ width: "200px" }}>Service Price</th>
                  <th style={{ width: "200px" }}>Scheduling Order</th>
                </tr>
              </thead>
              <tbody>
                {category.services?.map((service) => (
                  <tr
                    key={service.id}
                    data-bs-toggle="modal"
                    data-bs-target="#inSalon"
                    onClick={() => openModal(service)}
                  >
                    <td>{Capitalize(service.service_title)} </td>
                    <td>${ToFixed(service.service_price)} </td>
                    <td style={{ width: "200px" }}>
                      <Link to="!#">
                        {service.status == false ? "Inactive" : "Active"}
                        {/* {service.status ? "active" : "deactive"} */}
                        {/* <i className="fas fa-pencil"></i> */}
                      </Link>
                    </td>
                  </tr>
                ))}

                {/* <tr>
                  <td>Virtual Consulation </td>
                  <td style={{ width: "200px" }}>
                    <Link to="!#">
                      2 <i className="fas fa-pencil"></i>{" "}
                    </Link>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </>
  );
};

export default ServicesList;
