import React, { useEffect, useState } from "react";
import { API_BASE_URL, HEADERS, UPLOAD_URL } from "../../Helper/ApiConstants";
import { navData } from "../../Helper/Navigations";
import { Link, NavLink, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const Header = () => {
	let history = useHistory();


	const [business_locations, setBusinessLocations] = useState([]);

	const logout = () => {
		localStorage.removeItem("user_token");
		localStorage.removeItem("current_user");
		localStorage.removeItem("current_user_location");
		toast.success("Logout successfully");
		history.push("/signin");
	};

	const getBusinessLocations = () => {
		axios
			.get(`${API_BASE_URL}/get-business-location`, HEADERS)
			.then(function (response) {
				if (response.status === 200) {
					console.log(response.data.stores);

					setBusinessLocations(response.data.stores);

				}
			});
	};

	const changeLocationChangeHandler = () =>{
		console.log("sdfsdfsdf");
	}


	useEffect(() => {
		getBusinessLocations();

	}, []);

	// const token = localStorage.getItem("user_token");
	const location = JSON.parse( localStorage.getItem("current_user_location"));

	console.log(location);


	return (
		<>
		<header id="site-header" className="siteHeader container-fluid">
			<div className="leftSide logoNavbar">
				<div className="SiteLogo">
					<div className="customLink" 
						data-bs-toggle="collapse"
						href="#staffFullList1"
						aria-expanded="false"
						aria-controls="staffFullList">
						<div className="logoImg">
							<img src={UPLOAD_URL + "/" + location.store_logo} alt={location.store_title} />

							
							{/* <img src = { UPLOAD_URL+"/"+location.store_logo } alt = { location.store_title } /> */}
						</div>
						<div className="proName">
							{location.store_title}

							<small className="loc">{location.store_city}</small>
							
						</div>
					</div>					
				</div>
					<div id="staffFullList1" className="staffFullList collapse">
						<div className="head">
							<div className="groHeader">
								<label>Select</label>
								<a href="foo" className="allStaff selBtn">Location</a>
							</div>
						</div>
						<div className="listBody">
							
							<div className="stfListGroup">

								{ business_locations.map((location, i) => (

									<div className="snglStaff" key={i}>
										<div className="avtar" onClick={changeLocationChangeHandler}>
											<div className="snglAvtar">
												<span><img src={ UPLOAD_URL+'/'+location.store_logo } /></span>
											</div>
											<div className="avtName"> { location.store_title } </div>

											<a href="foo" className=" selBtn">{ location.store_city }</a>
										</div>
										
									</div>

								))}


							</div>

						</div>

					</div>

				

				<div className="mainSearch">
					<div className="searchIcon">
						<label htmlFor="clientSearch">
							<i
								className="fas fa-search"
								data-bs-toggle="collapse"
								href="#mainSearch"
								role="button"
								aria-expanded="false"
							></i>
						</label>
					</div>
					<div className="searchField collapse" id="mainSearch">
						<input
							id="clientSearch"
							type="search"
							placeholder="Search Clients"
						/>
					</div>
				</div>
				<div className="navBar">
					<ul className="navbar-nav">
						{ navData.map((nav, i) => (
							<li className={nav.className} key={i}><NavLink className="nav-link" aria-current="page" to={nav.to}>{nav.data}</NavLink></li>
						)) }
					</ul>
				</div>
			</div>
			<div className="rightSide accNotiSettings">
				<div className="allSettings">
					<div className="sngSetting setting">
						<button
							type="button"
							className="btn btn-danger btn-sm"
							onClick={logout}
						>
							logout
						</button>

						{/* {token && (
							<button
								type="button"
								className="btn btn-danger btn-sm"
								onClick={logout}
							>
								logout
							</button>
						)} */}
					</div>
					<div className="sngSetting allNoti">
						<Link to="javascripr:void(0);" className="fal fa-bell">
							<span className="notCount">25</span>
						</Link>
					</div>
					<div className="sngSetting setting">
					<NavLink
              className="nav-link"
              activeClassName="active"
              exact
              to="/settings"
            ><i className="fal fa-cog"></i></NavLink>
					</div>
					<div className="sngSetting myAcc">
						<Link to="!#" className="fal fa-user-circle"></Link>
					</div>
				</div>
			</div>

		</header>
		
		</>
	);
};

export default Header;
