import React, { useState, useEffect } from "react";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";
import axios from "axios";
import { withRouter } from "react-router-dom";
import Header from "../../components/Header/Header";
import AddLocation from "../../components/StoreLocations/AddLocation";
import ListofLocation from "../../components/StoreLocations/ListofLocation";
import LocationsDeleteModal from "../../components/StoreLocations/LocationDeleteModel";

const StoreLocations = () => {
	const [locations, setLocations] = useState([]);
	const [locationData, setLocationData] = useState([]);

	const listStoreLocation = () => {
		axios
			.get(`${API_BASE_URL}/list-store-location`, HEADERS)
			.then((response) => {
				if (response.status === 200) {
					setLocations(response.data.stores);
				}
			});
	};

	const openModal = (data) => {
		setLocationData(data);
	};

	useEffect(() => {
		listStoreLocation();
	}, []);

	return (
		<>
			<Header />
			<div id="settings" className="mainWrapper">
				<div id="settingsBar" className="statusBar settingsBar container-fluid">
					<div className="leftSide pageTitle">
						<h4 className="barTitle">Manage Location</h4>
					</div>
					<div className="rightSide AddNew">
						<button
							className="btn btn-primary purple ms-2"
							data-bs-toggle="modal"
							data-bs-target="#addLocation"
						>
							New Location
						</button>
					</div>
				</div>
				<div
					id="setPanelWrap"
					className="setWrap panelWrap bodyWrap p-0 container-fluid"
				>
					<div className="row m-0 g-0 justify-content-end">
						<div id="left" className="serMenu leftSide sideBarMenu p-0 col">
							<LeftSidebar />
						</div>
						<div
							id="right"
							className="rightSide col panel serDetails setBodyDetails"
						>
							<div className="row g-0">
								<div className="topPart col-12">
									<div className="row align-items-center">
										<div className="panelHead col">
											<h3 className="mb-0"><i className="fas fa-circle success"></i> Active Locations</h3>
										</div>
										<div className="col-3 right filterSearch staffInvite text-end">
											<button className="btn btn-simple">Active <i className="fas fa-chevron-down"></i></button>
											<button className="btn btn-simple ms-3 me-3"><i className="far fa-search"></i></button>
										</div>
									</div>
								</div>
								{/* list of location */}
								<ListofLocation locations={locations} openModal={openModal} />
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Add location modal */}
			<AddLocation listStoreLocation={listStoreLocation} />

			<LocationsDeleteModal
				modalId={locationData.id}
				listStoreLocation={listStoreLocation}
			/>
		</>
	);
};
export default withRouter(StoreLocations);
