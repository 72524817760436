import React from "react";
import { UpperCase, Capitalize, ToFixed } from "../../../Helper/Utilites";

const ProductCategoriesList = ({ pCategories, openModal }) => {
  return (
    <div className="row g-0 mt-2">
      <div className="col panel serblock p-0">
        <table className="panelTable shadow table table-hover">
          <thead>
            <tr>
              <th>Title</th>
              <th style={{ width: "500px" }}>Commission Enabled</th>
              <th style={{ width: "500px" }}>Retail/Professional</th>
              <th style={{ width: "200px" }}>Scheduling Order</th>
            </tr>
          </thead>
          <tbody>
            {pCategories.map((proCat, index) => (
              <tr
                data-bs-toggle="modal"
                data-bs-target="#UpdateModal"
                key={index}
                //data-id={proCat.id}
                onClick={() => openModal(proCat)}
              >
                <td>{Capitalize(proCat.category_name)}</td>
                <td style={{ width: "200px" }}>
                  <a href="!#">
                    {proCat.is_commissionable === 1 ? "Yes" : "No"}
                    <i className="fas fa-pencil"></i>
                  </a>
                </td>
                <td>
                  {proCat.is_retail === 1 ? " Retail " : " Professional "}
                </td>
                <td style={{ width: "200px" }}>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target={`#ModalDelete`}
                    onClick={() => openModal(proCat)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductCategoriesList;
