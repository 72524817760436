import React from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../../Helper/ApiConstants";

const AddProCateModal = ({ getProductCategoryList }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });

  //submit product-category
  const handleSubmitCategory = (data) => {
    axios
      .post(API_BASE_URL + "/add-product-category", data, HEADERS)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Category create successfully");
          getProductCategoryList();
          reset();
        }
      });
  };
  return (
    <div className="modal modalWrap fade" id="ModalCreate" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="ModalCreateLabel">
              <strong> New Product Category</strong>
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body modalBody row align-items-center">
            <div className="col-12">
              <form
                className="row g-0 justify-content-center"
                onSubmit={handleSubmit(handleSubmitCategory)}
              >
                <div className="form-group col-12">
                  <label htmlFor="category_name" className="form-label label">
                    Category Title
                  </label>
                  <input
                    type="text"
                    id="category_name"
                    className="form-control"
                    {...register("category_name", {
                      required: "please enter category title",
                    })}
                  />
                  {errors.category_name && (
                    <span className="text-danger">
                      {errors.category_name?.message}
                    </span>
                  )}
                </div>

                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    defaultChecked="checked"
                    id="is_commissionable"
                    {...register("is_commissionable")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="is_commissionable"
                  >
                    Products in this category are commissionable
                  </label>
                </div>

                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    defaultChecked="checked"
                    id="is_retail"
                    {...register("is_retail")}
                  />

                  <label className="form-check-label" htmlFor="is_retail">
                    Products in this category are retail
                  </label>
                </div>

                <div className="form-group col-12">
                  <div className="modal-body">
                    Retail products are able to be sold during checkout.
                    Products not marked as retail will not show during checkout.
                  </div>
                </div>

                <div className="form-group text-center submitBtn col-12">
                  <input
                    type="submit"
                    className="btn btn-primary purple"
                    data-bs-dismiss="modal"
                    value="Create Category"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProCateModal;
