import axios from "axios";
import React from "react";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";

const BookingDetails = () => {
	// const {
	// 	register,
	// 	handleSubmit,
	// 	reset,
	// 	formState: { errors, isDirty, isValid },
	// } = useForm({ mode: "onTouched" });

	//console.log(errors);

	// const onSubmit = (data) => {
	// 	console.log(data);
	// 	axios
	// 		.post(`${API_BASE_URL}/add-addons`, data, HEADERS)
	// 		.then((response) => {
	// 			if (response.status === 200) {
	// 				ListAddon();
	// 				reset();
	// 				toast.success("successfully!");
	// 			}
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});
	// };
	return (
		<div className="modal cardDetails fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div className="modal-dialog">
				<div className="modal-content">
					<header className="upper">
						<h4 className="styName">Alexa D’Agostino <small>(908) 907-1219</small></h4>
						<div className="rate">
							<div className="showRate"><strong>Show Rate :</strong> 100% </div>
							<div className="showRate avRate"><strong>Avg. Visit :</strong> $166 </div>
						</div>
						<div className="notify">
							<div className="snglNot">
								🔔
								<div className="fullDetail">
									<span>email reminder send Thu Dec 2 @ 11.34am</span>
									<span>sms reminder send Wed Dec 1 @ 5:17pm</span>
								</div>
							</div>
						</div>
					</header>
					<div className="modal-body1">

					
						<div className="cardDetBody">
							<div className="schDate"> <small>Wed, Aug 18th, 11:00am - 12:40pm</small> </div>
							<table className="table services">
								<tbody>
									<tr>
										<td className="time">11:00am</td>
										<td className="serName">Medium/ Long Cut</td>
										<td className="styName" width="90">Candis <i className="icon">🤍</i></td>
									</tr>
									<tr>
										<td className="time">12:00am</td>
										<td className="serName">Toner / Gloss - Without Blowout </td>
										<td className="styName" width="90">Ashley <i className="icon">❤️</i></td>
									</tr>
								</tbody>
							</table>
							<div className="toPrice">
								$128.00 <i className="fas fa-check-circle"></i>
								<small>Booked by Nicole on May 19th @ 1:16pm</small>
							</div>

							<div className="tagArea">
								<div className="tagLabel">Appointment Tags</div>
								<div className="tagsWrap">
									<div className="sngTag cover">
										<span>✅</span> Confirmation Text Sent <i className="far fa-times"></i>                                                        
									</div>
									<div className="sngTag add">
										<a className="collapsed addTag" data-bs-toggle="collapse" href="#allTags" role="button" aria-expanded="false" aria-controls="allTags">Add Tags +</a> 
										<div id="allTags" className="addWrap collapse">
											<div className="tagSearch">
												<input type="search" name="tagSearch" placeholder="Search Tags" />
												<button type="button"><i className="far fa-search"></i></button>
											</div>
											<div className="allTags ">
												<div className="snglTag">
													<div className="tagIcon">✂️</div>
													<div className="tagDescr">Appointment Adjustment</div>
												</div>
												<div className="snglTag">
													<div className="tagIcon">🐢</div>
													<div className="tagDescr">Running Late</div>
												</div>
												<div className="snglTag">
													<div className="tagIcon">😷</div>
													<div className="tagDescr">Covid Closure</div>
												</div>
												<div className="snglTag">
													<div className="tagIcon">🖌️</div>
													<div className="tagDescr">Color Corrections</div>
												</div>
												<div className="snglTag">
													<div className="tagIcon">✅</div>
													<div className="tagDescr">Confirmation Text Sent</div>
												</div>
												<div className="tagDescr">Jordan Cares</div>
											</div>
											<div className="snglTag">
												<div className="tagIcon">⚠️</div>
												<div className="tagDescr">Late Cancelation</div>
											</div>
											<div className="snglTag">
												<div className="tagIcon">💕</div>
												<div className="tagDescr">Loyal Client</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<footer className="lower">
							<div className="cancel">
								<a href="#" className=""><i className="fal fa-times-circle"></i> <span>Cancel</span></a>                                                
							</div>
						</footer>

					</div>
					<div className="modal-footer">
						<a href="#" className="btn btn-primary"><i className="fal fa-check"></i> <span>Mark as Confirmed</span></a>                                  
						<a href="#" className="btn btn-secondary"><i className="fal fa-pencil"></i> <span>Update</span></a>
					</div>
				</div>
			</div>
		</div>


	);
};

export default BookingDetails;
