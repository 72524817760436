import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../../Helper/ApiConstants";

const AddUpdateModal = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({ mode: "onTouched" });

  const handleSubmitCategory = (data) => {
    axios
      .post(API_BASE_URL + "/add-category", data, HEADERS)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Category create successfully");
          props.ListCategories();
          reset();
        }
      });
  };
  return (
    <div className="modal modalWrap fade" id="AddCategoryModal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              New Service Category
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body modalBody row align-items-center">
            <div className="col-12">
              <form
                className="row justify-content-center"
                onSubmit={handleSubmit(handleSubmitCategory)}
              >
                <div className="form-group col-12">
                  <label htmlFor="category_name" className="form-label label">
                    Category
                  </label>
                  <input
                    type="text"
                    id="category_name"
                    className="form-control"
                    placeholder="Category"
                    {...register("category_name", {
                      required: "please provide category",
                    })}
                  />
                  {errors.category_name && (
                    <span className="text-danger">
                      {errors.category_name?.message}
                    </span>
                  )}
                </div>
                <div className="form-group text-center submitBtn col-12">
                  <button
                    type="submit"
                    className="btn btn-primary purple"
                    data-bs-dismiss="modal"
                    disabled={!isDirty || !isValid}
                  >
                    Save Category
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUpdateModal;
