import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";

const AddLocation = ({ listStoreLocation }) => {
  const [logo, setLogo] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({
    // mode: "onTouched",
    mode: "onChange",
    defaultValues: {
      city: "",
      state: "",
      store_contact_no: "",
      store_email: "",
      store_title: "",
    },
  });

  const onImageChange = (e) => {
    const [file] = e.target.files;
    setLogo(URL.createObjectURL(file));
  };

  const handleSubmitLocation = (data) => {
    setLogo(data.location_logo[0].name);

    const formData = new FormData();

    formData.append("files", data.location_logo[0]);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("store_contact_no", data.store_contact_no);
    formData.append("store_email", data.store_email);
    formData.append("store_title", data.store_title);

    //console.log(...formData);

    axios
      .post(API_BASE_URL + "/add-store-location", formData, HEADERS)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Category create successfully");
          setLogo("");
          reset();
          listStoreLocation();
        }
      });
  };

  return (
    <div
      className="modal modalWrap fade"
      id="addLocation"
      tabIndex="-1"
      aria-labelledby="addLocation"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              <strong>New Service</strong>
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body modalBody row align-items-center">
            <div className="col-12">
              <form encType="multipart/form-data">
                <div className="modal-body modalBody row align-items-center">
                  <div className="col-12">
                    <div className="row g-0 justify-content-center mt-4 mb-4">
                      <div className="form-group col-6 pe-2">
                        <label
                          htmlFor="location_logo"
                          className="form-label label"
                        >
                          Logo<span>*</span>
                        </label>

                        <input
                          id="location_logo"
                          {...register("location_logo")}
                          type="file"
                          onChange={onImageChange}
                        />
                      </div>

                      <div className="form-group col-6 pe-2">
                        {logo && (
                          <img
                            src={logo}
                            id="location_logo_img"
                            alt=""
                            className="location_logo_img"
                          />
                        )}
                      </div>
                    </div>

                    <div className="row g-0 justify-content-center">
                      <div className="form-group col-12">
                        <label
                          htmlFor="store_title"
                          className="form-label label"
                        >
                          Location Title <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="store_title"
                          {...register("store_title", {
                            required: "Please Enter Store Title",
                          })}
                          className="form-control"
                        />
                        {errors.store_title && (
                          <span className="text-danger">
                            Store Location Required
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row g-0 justify-content-center">
                      <div className="form-group col-12">
                        <label
                          htmlFor="store_email"
                          className="form-label label"
                        >
                          Store Email <span>*</span>
                        </label>
                        <input
                          type="email"
                          id="store_email"
                          {...register("store_email", {
                            required: "Please Enter Store Email",
                            pattern: {
                              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                              message: "Please enter valid email",
                            },
                          })}
                          className="form-control"
                        />
                        {errors.store_email && (
                          <span className="text-danger">
                            {errors.store_email.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row g-0 justify-content-center">
                      <div className="form-group col-12">
                        <label
                          htmlFor="store_contact_no"
                          className="form-label label"
                        >
                          Store Contact No. <span>*</span>
                        </label>
                        <input
                          type="text"
                          {...register("store_contact_no", {
                            required: "Please Enter Store Contact no.",
                            pattern: {
                              //value: /^[0-9]*$/,
                              value: /[0-9]{3}-[0-9]{3}-[0-9]{4}/,
                              message: "Please Enter Numbers Only",
                            },
                          })}
                          id="store_contact_no"
                          className="form-control"
                        />
                        {errors.store_contact_no && (
                          <span className="text-danger">
                            {errors.store_contact_no.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row g-0 justify-content-center">
                      <div className="form-group col-12">
                        <label htmlFor="city" className="form-label label">
                          City <span>*</span>
                        </label>
                        <input
                          type="text"
                          {...register("city", {
                            required: "Please Enter Store City",
                          })}
                          id="city"
                          className="form-control"
                        />
                        {errors.city && (
                          <span className="text-danger">
                            {errors.city.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row g-0 justify-content-center">
                      <div className="form-group col-12">
                        <label htmlFor="state" className="form-label label">
                          State <span>*</span>
                        </label>
                        <input
                          type="text"
                          id="state"
                          {...register("state", {
                            required: "Please Enter Store State",
                          })}
                          className="form-control"
                        />
                        {errors.state && (
                          <span className="text-danger">
                            {errors.state.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="modal-footer row">
            <div className="col text-center">
              <button
                type="button"
                className="btn btn-primary btn-lg"
                onClick={handleSubmit(handleSubmitLocation)}
                disabled={!isDirty || !isValid}
              >
                Create Service
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLocation;
