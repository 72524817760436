import React, { useState,useEffect,reset } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { API_BASE_URL, HEADERS } from "../../../Helper/ApiConstants";

const AddUpdateModal = (props) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		defaultValues: props.category,
	});

	useEffect(() => {
		reset(props.category);
		}, [props.category]);

	const updateServiceCategory = (data) => {
		axios.post(API_BASE_URL + "/update-category", data, HEADERS).then((res) => {
			if (res.status === 200) {
				toast.success("Update Category successfully");
				props.ListCategories();
			}
		});
	};
	//console.log(inputCategory);
	return (
		<div
			className="modal modalWrap fade"
			id={"UpdateCateModal"}
		>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLabel">
							Update Service Category
						</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div className="modal-body modalBody row align-items-center">
						<div className="col-12">
							{/* {showSucces ? (
								<div className="alert alert-success" role="alert">
									<strong>Success!</strong> Category Saved Succcessfully
								</div>
							) : (
								""
							)} */}

							<form
								className="row justify-content-center"
								onSubmit={handleSubmit(updateServiceCategory)}
							>
								<div className="form-group col-12">
									<label htmlFor="" className="form-label label">Category</label>
									<input
										type="text"
										className="form-control"
										placeholder="Category"
										{...register("category_name", { required: true })}
									/>
								</div>
								<div className="form-group text-center submitBtn col-12">
									<button type="submit" className="btn btn-primary purple">Save Category</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddUpdateModal;
