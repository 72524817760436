import React from "react";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { API_BASE_URL, HEADERS } from "../../Helper/ApiConstants";
import { toast } from "react-toastify";
import Select from "react-select";

const EmployeesAddModal = ({ getEmployeeList, staffrole, location }) => {
	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors, isDirty, isValid },
	} = useForm({
		mode: "onChange",
	});

	//submit product form data
	const submitHandler = (data) => {
		//console.log(data);
		const { email, fname, lname, location, phone,price, sendMail, staffrole } = data;

		let username = fname + lname;
		let password = email;
		//let confirmPassword = password;
		let newData = {
			first_name: fname,
			last_name: lname,
			username: username,
			email: email,
			price: price,
			contact_number: phone,
			password: password,
			staff_roles: staffrole,
			location: location,
		};

		axios
			.post(`${API_BASE_URL}/employee-add`, newData, HEADERS)
			.then((response) => {
				if (response.status === 200) {
					toast.success("Employee create successfully");
					getEmployeeList();
					reset();
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<>
			{/* Add New Emplyee */}
			<div
				className="modal modalWrap fade "
				id="addNewEmployee"
				tabIndex="-1"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable panel-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">
								<strong>New Employee</strong>
							</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>
						<div className="modal-body modalBody row align-items-center">
							<div className="col-12">
								<form>
									<div className="row">
										<div className="form-group col-6">
											<label htmlFor="fname" className="form-label label">
												First Name <span>*</span>
											</label>
											<input
												type="text"
												className="form-control"
												placeholder="First Name"
												id="fname"
												{...register("fname", {
													required: "FirstName is Required",
												})}
											/>
											{errors.fname && (
												<span className="text-danger">
													{errors.fname.message}
												</span>
											)}
										</div>
										<div className="form-group col-6">
											<label htmlFor="lname" className="form-label label">
												Last Name <span>*</span>
											</label>
											<input
												type="text"
												className="form-control"
												placeholder="Last Name"
												id="lname"
												{...register("lname", {
													required: "LastName is Required",
												})}
											/>
											{errors.lname && (
												<span className="text-danger">
													{errors.lname.message}
												</span>
											)}
										</div>
									</div>
									<div className="row">
										<div className="form-group col-6">
											<label htmlFor="email" className="form-label label">
												Email Address <span>*</span>
											</label>
											<input
												type="email"
												className="form-control"
												placeholder="Email"
												id="email"
												{...register("email", {
													required: "Email is Required",
													pattern: {
														value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
														message: "Please enter valid email",
													},
												})}
											/>
											{errors.email && (
												<span className="text-danger">
													{errors.email.message}
												</span>
											)}
										</div>
										<div className="form-group col-6">
											<label htmlFor="phone" className="form-label label">
												Phone <span>*</span>
											</label>
											<input
												type="tel"
												className="form-control"
												placeholder="phone"
												id="phone"
												{...register("phone", {
													required: "Phone is Required",
													pattern: {
														//value: /^[0-9]*$/,
														value: /[0-9]{3}-[0-9]{3}-[0-9]{4}/,
														message: "Please Enter Numbers Only",
													},
												})}
											/>
											{errors.phone && (
												<span className="text-danger">
													{errors.phone.message}
												</span>
											)}
										</div>
									</div>
									<div className="row">
										<div className="form-group">
											<label htmlFor="price" className="form-label label">Price <span>*</span></label>
											<input
												type="text"
												className="form-control"
												placeholder="Price"
												id="price"
												{...register("price", {
													required: "Price is Required",
													pattern: {
														value: /^[0-9]*$/,
														//value: /[0-9]{3}-[0-9]{3}-[0-9]{4}/,
														message: "Please Enter Numbers Only",
													},
												})}
											/>
											{errors.price && (
												<span className="text-danger">{errors.price.message}</span>
											)}
										</div>
									</div>



									<div className="row">
										<div className="form-group">
											<label htmlFor="staffrole" className="form-label label">
												Assign Staff Role <span>*</span>
											</label>

											<Controller
												name="staffrole"
												control={control}
												render={({ field: { onChange, value, ref } }) => (
													<Select
														inputRef={ref}
														isSearchable={true}
														options={staffrole}
														value={staffrole.find((c) => c.value === value)}
														onChange={(val) => onChange(val.value)}
													/>
												)}
												rules={{ required: "This is required" }}
											/>

											{errors.staffrole && (
												<span className="text-danger">
													{errors.staffrole.message}
												</span>
											)}
										</div>
									</div>
									{/* <div className="row g-0 justify-content-center mb-4">
										<div className="form-group col-12">
											<label
												htmlFor="privilegegroup"
												className="form-label label"
											>
												Assign Privilege group <span>*</span>
											</label>
											<select
												//defaultValue="2"
												//multiple={false}
												className="mdb-select md-form form-select"
												id="privilegegroup"
												{...register("privilegegroup", {
													required: "Privilege group is Required",
												})}
											>
												<option value="">Select Privilege group *</option>
												<option value="1">Option 1</option>
												<option value="2">Option 2</option>
												<option value="3">Option 3</option>
												<option value="4">Option 4</option>
												<option value="5">Option 5</option>
												<option value="6">Option 6</option>
												<option value="7">Option 7</option>
												<option value="8">Option 8</option>
												<option value="9">Option 9</option>
												<option value="10">Option 10</option>
											</select>
											{errors.privilegegroup && (
												<span className="text-danger">
													{errors.privilegegroup.message}
												</span>
											)}
										</div>
									</div> */}
									<div className="row">
										<div className="form-group col-12 mb-0">
											<label htmlFor="location" className="form-label label">
												Assign Location(s) <span>*</span>
											</label>

											<Controller
												control={control}
												id="locations"
												defaultValue={location.map((c) => c.value)}
												name="location"
												render={({ field: { onChange, value, ref } }) => (
													<Select
														inputRef={ref}
														value={location.filter((c) =>
															value.includes(c.value)
														)}
														onChange={(val) =>
															onChange(val.map((c) => c.value))
														}
														options={location}
														isMulti
													/>
												)}
											/>

											{errors.location && (
												<span className="text-danger">
													{errors.location.message}
												</span>
											)}
										</div>
									</div>
									<div className="row">
										<div className="form-check checkbox">
											<input
												className="form-check-input"
												type="checkbox"
												defaultValue=""
												id="sendMail"
												{...register("sendMail", {
													required: "confirmation is required",
												})}
											/>

											<label className="form-check-label" htmlFor="sendMail">
												<strong>Send Email Invite</strong>
												<small>So your employee can sign up or login</small>
											</label>
										</div>
										{errors.sendMail && (
											<span className="text-danger">
												{errors.sendMail.message}
											</span>
										)}
									</div>
								</form>
							</div>
						</div>
						<div className="modal-footer row g-0">
							<div className="col text-center">
								<button
									type="button"
									className="btn btn-primary btn-lg"
									onClick={handleSubmit(submitHandler)}
									disabled={!isDirty || !isValid}
								>
									Create Employee
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Add New Emplyee */}
		</>
	);
};

export default EmployeesAddModal;
