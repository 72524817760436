import React from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import { API_BASE_URL } from "../../Helper/ApiConstants";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Register = () => {
	const {
		register,
		handleSubmit,
		watch,
		reset,
		formState: { errors },
	} = useForm({
		// mode: "onChange",
		mode: "onTouched",
	});

	//   check password event
	const password = watch("password");

	let history = useHistory();

	const registerSubmit = (data) => {
		axios
			.post(`${API_BASE_URL}/register`, data)
			.then(function (response) {
				console.log(response);

				if (response.status === 200) {
					toast.success("Register successfully");
					reset();
					history.push("/signin");
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const token = localStorage.getItem("user_token");
	if (token) {
		return <Redirect to="/selectlocation" />;
	}

	return (
		<>
			{/* <ToastContainer /> */}
			<div className="container">
				<div className="row align-items-center vh-100">
					<div className="col-6 mx-auto">
						<div className="form-center center">
							<div
								className="card text-dark bg-light mb-3"
								style={{ maxWidth: "18rem" }}
							>
								<form onSubmit={handleSubmit(registerSubmit)} noValidate>
									<div className="card-header">Register</div>
									<div className="card-body">
										<div className="mb-3">
											<label className="form-label" htmlFor="username">
												username
											</label>
											<input
												type="text"
												id="username"
												className="form-control"
												placeholder="Username"
												{...register("username", {
													required: "Username is required",
												})}
											/>
											{errors.username && (
												<span className="text-danger">
													{errors.username.message}
												</span>
											)}
										</div>

										<div className="mb-3">
											<label className="form-label" htmlFor="email">
												email
											</label>
											<input
												type="email"
												id="email"
												className="form-control"
												placeholder="Email"
												{...register("email", {
													required: "email is required",
												})}
											/>
											{errors.email && (
												<span className="text-danger">
													{errors.email.message}
												</span>
											)}
										</div>

										<div className="mb-3">
											<label className="form-label" htmlFor="email">
												Brand
											</label>
											<input
												type="text"
												id="brand_name"
												className="form-control"
												placeholder="brand_name"
												{...register("brand_name", {
													required: "brand_name is required",
												})}
											/>
											{errors.brand_name && (
												<span className="text-danger">
													{errors.brand_name.message}
												</span>
											)}
										</div>

										<div className="mb-3">
											<label className="form-label" htmlFor="password">
												password
											</label>
											<input
												type="password"
												id="password"
												className="form-control"
												placeholder="Password"
												{...register("password", {
													required: "Password contains 7-14 character",
													/* pattern: {
														value: /^[A-Za-z]\w{7,14}$/,
														message:
															"Password should include at least one uppercase, one numeric value and one special character",
													} ,*/
													minLength: {
														value: 8,
														message: "Password must have at least 8 characters",
													},
													maxLength: {
														value: 15,
														message:
															"Password don't have more then 15 characters",
													},
												})}
											/>
											{errors.password && (
												<span className="text-danger">
													{errors.password.message}
												</span>
											)}
										</div>

										<div className="mb-3">
											<label className="form-label" htmlFor="confirPassword">confirm password</label>
											<input
												type="password"
												id="confirmPassword"
												className="form-control"
												placeholder="ConfirmPassword"
												onPaste={(e) => {
													e.preventDefault();
													return false;
												}}
												{...register("confirmPassword", {
													required: "ConfirmPassword is required",
													validate: (value) =>
														value === password || "The passwords do not match",
												})}
											/>
											{errors.confirmPassword && (
												<span className="text-danger">
													{errors.confirmPassword.message}
												</span>
											)}
										</div>

										<div className="mb-3">
											<button className="btn btn-primary" type="submit">
												Register
											</button>
										</div>
										<div className="mb-3">
											<p className="form-text">
												Already have an account?
												<Link to="/signin" className="pl-3">here</Link>
											</p>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Register;
