import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL, HEADERS,UPLOAD_URL } from "../../Helper/ApiConstants";
import { withRouter } from "react-router-dom";
import Header from "../../components/Header/Header";
import LeftStatusBar from "../../components/statusBar/LeftStatusBar";


const Clients = () => {

	const [clients, setClients] = useState([]);
	const [last_page, setLastPage] = useState([]);

	var rows =[];


	const getClientsList = () => {
		axios
			.get(`${API_BASE_URL}/clients-list`, HEADERS)
			.then(function (response) {
				if (response.status === 200) {
					setClients(response.data.data);
					//console.log(response.data.data);

					setLastPage(response.data.last_page);

				}
			});
	};


	const getPaginateClientsList = (page) => {

		axios
			.get(`${API_BASE_URL}/clients-list?page=`+page, HEADERS)
			.then(function (response) {
				if (response.status === 200) {
					setClients(response.data.data);
					//console.log(response.data.data);

					setLastPage(response.data.last_page);

				}
			});
	};


	useEffect(() => {
		getClientsList();
		
	}, []);


for (let i = 1; i <= last_page; i++) {
    rows.push(<li key={i} className="page-item"><a className="page-link" href="#" onClick={ () => getPaginateClientsList(i) }>{i}</a></li>);
  }

	return (
		<React.Fragment>
			<Header />
			

			<div className="row g-0 mt-2">
				<div className="col panel serblock p-0">
					<table className="panelTable shadow shadow table table-hover">
						<thead>
							<tr>
								<th className="firstName">
									First Name <i className="far fa-arrow-up"></i>
								</th>
								<th className="lastName">
									Last Name <i className="far fa-arrow-up"></i>
								</th>
								<th className="mobile">
									Mobile <i className="far fa-arrow-up"></i>
								</th>
								<th className="email">
									Email <i className="far fa-arrow-up"></i>
								</th>
								<th className="invite">
									Invite <i className="far fa-arrow-up"></i>
								</th>
								<th className="staffRole">
									Staff Role <i className="far fa-arrow-up"></i>
								</th>
								<th className="privilegeGroup col-2">
									Status
									<i className="far fa-arrow-up"></i>
								</th>
							</tr>
						</thead>
						<tbody>
							{clients.map((client, i) => (
								<tr
									data-bs-toggle="modal"
									data-bs-target="#employeeEdits"
									key={i}
									
								>
									<td className="firstName">
										<span className="avatar">
											{ client.profile_img == null && (
												<i className="fas fa-user-circle"></i>
											)}

											{ client.profile_img != null && (
												<img
													className="profile-img"
													src={`${UPLOAD_URL}/${ client.profile_img}`}
													alt={ client.first_name}
												/>
											)}
										</span>
										{ client.first_name}
									</td>
									<td className="lastName">{ client.last_name}</td>
									<td className="mobile">{ client.contact_number || "-"}</td>
									<td className="email">{ client.email}</td>
									<td className="invite">Confirmed</td>
									<td className="staffRole">{ client.staffrole}</td>
									<td className="privilegeGroup col-2">
										{client.status == true ? "Active" : "Deactive"}
									</td>
								</tr>
							))}
						</tbody>
					</table>


					<nav aria-label="Page navigation example">

						<ul className="pagination justify-content-center">
							{rows}

							{/*<li className="page-item disabled"><a className="page-link" href="#" tabIndex="-1" aria-disabled="true">Previous</a></li>						
							<li className="page-item"><a className="page-link" href="#">2</a></li>
							<li className="page-item"><a className="page-link" href="#">3</a></li>
							<li className="page-item"><a className="page-link" href="#">Next</a></li>*/}
						</ul>

					</nav>
				</div>
			</div>

		 
		</React.Fragment>
	);
};

export default withRouter(Clients);
