import React from "react";
import PageNotFound from "../../components/PageNotFound/PageNotFound";

const Error = () => {
  return (
    <React.Fragment>
      <PageNotFound />
    </React.Fragment>
  );
};

export default Error;
